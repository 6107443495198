import { defineStore } from "pinia";

export const useStore = defineStore("store", {
  state: () => ({
    selectedTournament: "",
    playingTournament: "",
    search: "",
  }),

  actions: {
    setTournament(id) {
      this.selectedTournament = id;
    },
    clearTournament() {
      this.selectedTournament = "";
    },
    setPlayingTournament(id) {
      this.playingTournament = id;
    },
    clearPlayingTournament() {
      this.playingTournament = "";
    },
    setSearch(str) {
      this.search = str;
    },
  },
});

<template>
  <div class="container">
    <div v-if="!phoneOK">
      <div class="card mx-auto mt-5" style="width: 18rem">
        <div class="card-body">
          <h5 class="card-title">Spot Prizes</h5>
          <div class="row">
            <input
              type="text"
              class="form-control"
              placeholder="Phone"
              v-model.trim="phone"
            />
          </div>
          <div class="row mt-1">
            <button class="btn btn-primary" @click="submitPhone()">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="phoneOK && !tournamentData.id" class="mt-1">
      <div v-for="t in tournamentList" :key="t.id">
        <button class="btn btn-primary mr-1" @click="selectTournament(t)">
          {{ t.name }}
        </button>
      </div>
    </div>
    <div v-if="tournamentData.id">
      <i class="fa fa-close float-end" @click="cancelTournament()"></i>
      <!-- {{ tournamentData.spotprize }} :{{ phone }} -->
      <!-- {{ tournamentData.fourballs }}  -->
      Logged in as: {{ phone }}
      <ul class="list-group">
        <li class="list-group-item" v-for="f in sorted_fourballs" :key="f.id">
          <h6>Group: {{ f.sno }}, {{ f.id }}</h6>

          <ul class="list-group">
            <li
              class="list-group-item smallList"
              v-for="p in f.players"
              :key="p"
            >
              <div class="row">
                <div class="col" style="margin: auto" @click="selectPlayer(p)">
                  <div class="btn btn-sm btn-outline-primary">
                    {{ p.player }}
                  </div>
                </div>
                <div class="col">
                  <button
                    class="btn btn-success btn-sm mx-2"
                    v-for="b in fetchPrizes(p.phone, f.prizes)"
                    :key="b"
                    @click="showDeletePrize(b, f.id, p.phone)"
                  >
                    {{ b }}
                    <i
                      class="fa fa-trash-o ms-5"
                      v-if="
                        delete_prize_name == b && delete_fourball_id == f.id
                      "
                      @click="deletePrize(b, f.id, p.phone)"
                    ></i>
                  </button>
                </div>
                <div
                  class="col"
                  v-if="selected_player == p && fetchPrizes(p.phone, f.prizes)"
                >
                  <span v-for="prize in tournamentData.spotprize" :key="prize">
                    <button
                      class="btn btn-sm btn-primary mx-5"
                      v-if="prize.phone == phone"
                      @click="addPrize(prize, p.phone, f.id)"
                    >
                      {{ prize.name }}
                    </button>
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
import { ref, watchEffect, onBeforeUnmount } from "vue";
import {
  collection,
  getDocs,
  query,
  where,
  onSnapshot,
  arrayUnion,
  arrayRemove,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
const phone = ref("");
const phoneOK = ref(false);
const tournamentList = ref([]);
const selectedTournament = ref("");
const tournamentData = ref({});
const sorted_fourballs = ref([]);
const selected_player = ref({});
const delete_prize_name = ref("");
const delete_fourball_id = ref("");
var unsub;
onBeforeUnmount(() => {
  console.log("unmounting...");
  unsub();
});
const submitPhone = async () => {
  //console.log(phone.value);
  const querySnapshot = await getDocs(collection(db, "tournaments"));
  querySnapshot.forEach((docy) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(docy.id, " => ", docy.data());
    if (checkIfPhonePresent(docy.data().spotprize)) {
      tournamentList.value.push({ id: docy.id, ...docy.data() });
      phoneOK.value = true;
    }
  });
};
watchEffect(() => {
  if (tournamentData.value.id) {
    sorted_fourballs.value = [];
    for (const key in tournamentData.value.fourballs) {
      sorted_fourballs.value.push({
        id: key,
        ...tournamentData.value.fourballs[key],
      });
    }
    sorted_fourballs.value.sort((a, b) => {
      let fa = a.sno;
      let fb = b.sno;
      //console.log(fa, fb);
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
  }
});
const checkIfPhonePresent = (prizearr) => {
  var found = false;
  prizearr.forEach((p) => {
    if (p.phone == phone.value) {
      console.log("present!!");
      found = true;
    }
  });
  return found;
};
const selectTournament = (tournament) => {
  unsub = onSnapshot(doc(db, "tournaments", tournament.id), (docy) => {
    tournamentData.value = { id: docy.id, ...docy.data() };
  });
};
const cancelTournament = () => {
  tournamentData.value = {};
  phone.value = "";
  phoneOK.value = false;
  tournamentList.value = [];
  delete_fourball_id.value = "";
  delete_prize_name.value = "";
  unsub();
};
const selectPlayer = (p) => {
  if (selected_player.value != p) {
    selected_player.value = p;
  } else {
    selected_player.value = {};
  }
};
const addPrize = async (prize, phone, fourballid) => {
  //console.log(prize.name, phone, fourballid);
  if (checkIfPrizeCanBeAdded(prize, phone, fourballid)) {
    const washingtonRef = doc(db, "tournaments", tournamentData.value.id);
    await updateDoc(washingtonRef, {
      // stages: stages,
      ["fourballs." + fourballid + ".prizes"]: arrayUnion({
        prize: prize.name,
        phone: phone,
      }),
    });
  }

  //console.log(tournamentData.value.fourballs[fourballid]);
};
const checkIfPrizeCanBeAdded = (prize, playerphone, fourballid) => {
  var oktoadd = true;
  const prizename = prize.name;
  console.log(prizename, playerphone, fourballid);
  // tournamentData.value.fourballs.forEach((four) => {
  //   if (pr.name == prizename) {
  //     oktoadd = false;
  //   }
  // });
  for (const key in tournamentData.value.fourballs) {
    console.log(key);
    if (key == fourballid) {
      tournamentData.value.fourballs[key].prizes.forEach((p) => {
        if (p.prize == prizename) {
          oktoadd = false;
        }
      });
    }
  }
  return oktoadd;
};
const fetchPrizes = (phone, list) => {
  const prizes = [];
  //console.log(phone, list);
  list.forEach((p) => {
    if (p.phone == phone) {
      prizes.push(p.prize);
    }
  });
  return prizes;
};
const showPrizeButton = () => {};
const showDeletePrize = (prizename, fourballid, playerphone) => {
  //console.log(prizename, fourballid, playerphone);
  if (checkIfCaddyAuthorizedToDeletePrize(prizename)) {
    if (
      delete_prize_name.value != prizename &&
      delete_fourball_id.value != fourballid
    ) {
      delete_prize_name.value = prizename;
      delete_fourball_id.value = fourballid;
    } else {
      delete_prize_name.value = "";
      delete_fourball_id.value = "";
    }
  }
};
const checkIfCaddyAuthorizedToDeletePrize = (prizename) => {
  var allowed = false;
  tournamentData.value.spotprize.forEach((prize) => {
    if (prize.name == prizename && prize.phone == phone.value) {
      allowed = true;
    }
  });
  return allowed;
};
const deletePrize = async (prizename, fourballid, playerphone) => {
  const washingtonRef = doc(db, "tournaments", tournamentData.value.id);
  await updateDoc(washingtonRef, {
    // stages: stages,
    ["fourballs." + fourballid + ".prizes"]: arrayRemove({
      prize: prizename,
      phone: playerphone,
    }),
  });
  delete_fourball_id.value = "";
  delete_prize_name.value = "";
};
</script>
<style scoped>
.dropdown-item {
  padding: 1px;
  font-size: 12px;
}
.smallList {
  padding: 1px;
  font-size: 12px;
}
.btn-sm {
  padding: 2px;
}
</style>

<template>
  <div class="container border rounded">
    <PlayerNavbar></PlayerNavbar>
    <div
      class="d-flex justify-content-evenly mt-3 mb-3"
      v-if="!store.playingTournament"
    >
      <!-- {{ myTournaments }} -->
      <div v-for="t in myTournaments" :key="t.id">
        <button class="btn btn-primary mr-1" @click="selectTournament(t.id)">
          {{ t.name }}
        </button>
      </div>
    </div>

    <div v-if="store.playingTournament">
      <Card></Card>
    </div>
  </div>
</template>
<script setup>
import PlayerNavbar from "./PlayerNavbar.vue";
import Card from "./Card.vue";
import { onMounted, ref } from "vue";
import { db, auth } from "../firebase/config";
import { useStore } from "../store/index";
import { collection, getDocs } from "firebase/firestore";

const myTournaments = ref([]);
const store = useStore();
onMounted(() => {
  fetchAllTournaments();
});
const fetchAllTournaments = async () => {
  //console.log(auth.currentUser.phoneNumber);
  const querySnapshot = await getDocs(collection(db, "tournaments"));
  querySnapshot.forEach((docy) => {
    // docy.data().players.forEach((p) => {
    //   if ("+91" + p.phone == auth.currentUser.phoneNumber) {
    //     myTournaments.value.push({ id: docy.id, ...docy.data() });
    //   }
    // });
    for (const key in docy.data().players) {
      //console.log(`${property}: ${object[property]}`);
      if (
        "+91" + docy.data().players[key].phone ==
        auth.currentUser.phoneNumber
      ) {
        myTournaments.value.push({ id: docy.id, ...docy.data() });
      }
    }
  });
};
const selectTournament = (id) => {
  console.log(id);
  store.setPlayingTournament(id);
};
</script>

<template>
  <div class="container">
    <Navbar></Navbar>
    <div class="row mt-2">
      <div class="col">
        <!-- {{ store.selectedTournament }} {{ id }} -->

        <h5>
          {{ tournamentName }}
          <i
            class="fa fa-close float-right"
            v-if="store.selectedTournament"
            @click="clearTournament()"
          ></i>
        </h5>

        <table class="table table-sm mt-2">
          <tr
            v-for="(t, index) in sorted_players"
            :key="t"
            class="border rounded smallList"
          >
            <td>{{ index + 1 }}</td>
            <td :class="selectedPlayerClass(t.phone)" class="float-start">
              <div @click="selectPlayer(t)">{{ t.player }}</div>
            </td>
            <td>{{ t.phone }}</td>
            <td>{{ t.handicap }}</td>
          </tr>
        </table>
      </div>
      <div class="col">
        <div class="row" v-if="!store.selectedTournament">
          <ul class="list-group">
            <li
              v-for="t in tournaments"
              class="list-group-item"
              :key="t.id"
              @click="selectThisTournament(t.id)"
            >
              {{ t.name }}
            </li>
          </ul>
        </div>
        <div class="row" v-if="store.selectedTournament">
          <ul class="list-group">
            <li class="list-group-item" v-for="p in fourball" :key="p">
              <div class="row">
                <div class="col">
                  <i
                    class="fa fa-angle-down float-start"
                    @click="specificFourBall(p)"
                  ></i>
                  <ul
                    class="list-group"
                    v-if="shift_player_specific_fourball == p"
                  >
                    <li
                      class="list-group-item text-end smallList"
                      v-for="fb in sorted_fourballs"
                      :key="fb.id"
                      @click="shiftToSpecificFourball(fb.id, p)"
                    >
                      Shift to Group: {{ fb.sno }}
                    </li>
                  </ul>
                </div>
                <div class="col smallList">{{ p.player }}</div>
                <div class="col">
                  <i class="fa fa-close" @click="removeFromTempFourball(p)"></i>
                </div>
              </div>
            </li>
          </ul>
          <button
            class="btn btn-sm btn-primary"
            v-if="fourball.length"
            @click="addFourBall()"
          >
            Add
          </button>

          <!-- /////////////////////new fourballs list////////////////////////////// -->
          <ul class="list-group">
            <li
              class="list-group-item smallList"
              v-for="four in sorted_fourballs"
              :key="four.id"
            >
              <div class="row">
                <div class="dropdown col">
                  <i
                    class="float-start dropdown-toggle"
                    :id="four.id"
                    data-bs-toggle="dropdown"
                    @click="renumberFourball(four.id)"
                    v-if="delete_fourball_id == four.id"
                  ></i>

                  <ul class="dropdown-menu" :aria-labelledby="four.id">
                    <li
                      class="dropdown-item text-center border"
                      v-for="index in getNumberOfFourballs()"
                      :key="index"
                      @click="reOrderFourball(four.id, index)"
                    >
                      Change to SNo: {{ index }}
                    </li>
                  </ul>
                </div>

                <div class="col smalltext">
                  <span @click="selectToDeleteFourball(four.id)">
                    Group: {{ four.sno }}</span
                  >
                </div>
                <div class="col" v-if="checkIfScrambleTournament()">
                  <ScrambleTeamName
                    :id="four.id"
                    :fourballs="tournamentFourballs"
                  ></ScrambleTeamName>
                </div>
                <div class="col">
                  <i
                    class="fa fa-close float-end"
                    @click="deleteFourball(four.id)"
                    v-if="delete_fourball_id == four.id"
                  ></i>
                </div>
              </div>

              <ul class="list-group">
                <li
                  class="list-group-item smallList"
                  v-for="p in four.players"
                  :key="p"
                >
                  <span class="float-start">{{ p.player }}</span>
                  <!-- <span class="float-end"> {{ getPlayerPrize(p, key) }}</span> -->
                  <i
                    class="fa fa-angle-down float-end"
                    @click="selectToShift(p)"
                  ></i>
                  <ul class="list-group" v-if="shiftPlayer == p">
                    <li
                      class="list-group-item text-end smallList"
                      v-for="fb in sorted_fourballs"
                      :key="fb.id"
                      @click="shiftToFourball(fb.id, p, four.id)"
                    >
                      Shift to Group: {{ fb.sno }}
                    </li>
                    <li class="list-group-item smallList bg-danger">
                      <i
                        class="fa fa-trash-o"
                        @click="deleteFromFourball(four.id, p)"
                      ></i>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
          <!-- ///////////////////////////////////////////////////////////////// -->
          <!-- <ul class="list-group">
            <li
              class="list-group-item smallList"
              v-for="(value, key) in tournamentFourballs"
              :key="key"
            >
              {{ value.sno }}
              <i class="fa fa-close float-end" @click="deleteFourball(key)"></i>
              <ul class="list-group">
                <li
                  class="list-group-item smallList"
                  v-for="p in value.players"
                  :key="p"
                >
                  {{ p.player }}
                  <span class="float-end"> {{ getPlayerPrize(p, key) }}</span>
                  <i
                    class="fa fa-angle-down float-end"
                    @click="selectToShift(p)"
                  ></i>
                  <ul class="list-group" v-if="shiftPlayer == p">
                    <li
                      class="list-group-item text-end"
                      v-for="fb in tournamentFourballs"
                      :key="fb.id"
                    >
                      {{ fb.sno }}, {{ fb.id }}
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul> -->
        </div>
      </div>
      <div class="col" v-if="store.selectedTournament">
        <span @click="setAction('prize')">
          <button class="btn btn-primary btn-sm mt-1">Prizes</button>
        </span>
        <ul class="list-group mx-2">
          <li class="list-group-item" v-if="action == 'prize'">
            <div class="row">
              <div class="col">
                <input
                  type="text"
                  class="form-control smalltext"
                  placeholder="Prize"
                  v-model.trim="prizeName"
                />
              </div>
              <div class="col">
                <input
                  type="text"
                  class="form-control smalltext"
                  placeholder="Phone"
                  v-model.trim="prizePhone"
                />
              </div>
              <div class="col">
                <button class="btn btn-sm btn-primary" @click="addPrize()">
                  Add
                </button>
              </div>
            </div>
          </li>
          <li class="list-group-item smallList" v-for="p in prizeList" :key="p">
            <div class="row">
              <div class="col" @click="selectDeletePrize(p.name)">
                {{ p.name }}
              </div>
              <div class="col">{{ p.phone }}</div>
              <div class="col">
                <i
                  class="fa fa-close"
                  v-if="p.name == select_delete_prize"
                  @click="deletePrize(p)"
                ></i>
              </div>
            </div>
          </li>
        </ul>
        <span @click="setAction('regd')"
          ><button class="btn btn-primary btn-sm mt-1">Regd & Tag</button>
        </span>
        <ul class="list-group mx-1">
          <li class="list-group-item smallList" v-if="action == 'regd'">
            <div class="row">
              <div class="col">
                <input
                  type="text"
                  class="form-control smalltext"
                  placeholder="Phone"
                  v-model.trim="regd_phone"
                />
              </div>
              <div class="col">
                <button class="btn btn-sm btn-primary" @click="addRegdPhone()">
                  Add
                </button>
              </div>
            </div>
          </li>
          <li
            class="list-group-item smallList"
            v-for="reg in regdPhones"
            :key="reg"
          >
            <div class="row">
              <div class="col" @click="selectDeleteRegdPhone(reg)">
                {{ reg }}
              </div>
              <div class="col">
                <i
                  class="fa fa-close"
                  v-if="delete_regd_phone == reg"
                  @click="deleteRegPhone(reg)"
                ></i>
              </div>
            </div>
          </li>
        </ul>
        <span @click="setAction('score')">
          <button class="btn btn-primary btn-sm mt-1">Scoring</button></span
        >

        <ul class="list-group">
          <li class="list-group-item smallList" v-if="action == 'score'">
            <div class="row">
              <div class="col">
                <input
                  type="text"
                  class="form-control smalltext"
                  placeholder="Phone"
                  v-model.trim="score_phone"
                />
              </div>
              <div class="col">
                <button
                  class="btn btn-sm btn-primary"
                  @click="addScoringPhone()"
                >
                  Add
                </button>
              </div>
            </div>
          </li>
          <li
            class="list-group-item smallList"
            v-for="sc in scorePhones"
            :key="sc"
          >
            <div class="row">
              <div class="col" @click="selectDeleteScorePhone(sc)">
                {{ sc }}
              </div>
              <div class="col">
                <i
                  class="fa fa-close"
                  v-if="delete_scoring_phone == sc"
                  @click="deleteScorePhone(sc)"
                ></i>
              </div>
            </div>
          </li>
        </ul>
        <span @click="setAction('resultcategory')">
          <button class="btn btn-primary btn-sm mt-1">
            ResultCategory
          </button></span
        >
        <ul class="list-group">
          <li
            class="list-group-item smallList"
            v-if="action == 'resultcategory'"
          >
            <div class="row">
              <div class="col">
                <input
                  type="text"
                  class="form-control smalltext float-start"
                  placeholder="Result handicap category"
                  v-model.trim="result_category"
                />
              </div>
              <div class="col">
                <button
                  class="btn btn-sm btn-primary"
                  @click="addResultCategory()"
                >
                  Add
                </button>
              </div>
            </div>
          </li>
          <li
            class="list-group-item smallList"
            v-for="sc in resultCats"
            :key="sc"
          >
            <div class="row">
              <div class="col" @click="selectDeleteResultCat(sc)">
                {{ sc }}
              </div>
              <div class="col">
                <i
                  class="fa fa-close"
                  v-if="delete_result_cat == sc"
                  @click="deleteResultCat(sc)"
                ></i>
              </div>
            </div>
          </li>
        </ul>
        <ul class="list-group">
          <button
            class="btn btn-primary btn-sm mt-1"
            @click="generatePrizeList()"
          >
            SpotPrizeList
          </button>
          <ul class="list-group">
            <li class="list-group-item" v-for="pr in spotPrizeList" :key="pr">
              {{ pr.name }}
              <ul class="list-group">
                <li
                  class="list-group-item smallList"
                  v-for="pw in pr.winners"
                  :key="pw"
                >
                  {{ fetchPlayerName(pw) }}
                </li>
              </ul>
            </li>
          </ul>
        </ul>
        <ScrambleScorers v-if="checkIfScrambleTournament()"></ScrambleScorers>
        <OpenCards v-if="checkIfScrambleTournament()"></OpenCards>
      </div>
    </div>
  </div>
</template>
<script setup>
import Navbar from "./AdminNavbar.vue";
import ScrambleScorers from "./ScrambleScorers.vue";
import ScrambleTeamName from "./ScrambleTeamName.vue";
import OpenCards from "../scramble/OpenCards.vue";
import { db, auth } from "../../firebase/config";
import {
  query,
  collection,
  where,
  onSnapshot,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  deleteField,
} from "firebase/firestore";
import { useStore } from "../../store/index";
import { onBeforeUnmount, onMounted, ref, watchEffect } from "vue";
import { customAlphabet } from "nanoid";
const nanoid = customAlphabet("123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ", 6);
var tournaments = ref([]);
var players = ref([]);
var shotgun = ref("");
const error = ref("");
var sorted_players = ref([]);
const playerName = ref("");
const playerHandicap = ref("");
const playerPhone = ref("");
const store = useStore();
const selectedPlayer = ref("");
const id = ref("");
const tournamentName = ref("");
let XLSX = require("xlsx");
const excelData = ref([]);
const prizePhone = ref("");
const prizeName = ref("");
const prizeList = ref([]);
const tournamentFourballs = ref([]);
const fourball = ref([]);
const shiftPlayer = ref({});
const sorted_fourballs = ref([]);
const delete_fourball_id = ref("");
const shift_player_specific_fourball = ref({});
const select_delete_prize = ref("");
const renumber_fourball = ref("");
const regd_phone = ref("");
const score_phone = ref("");
const regdPhones = ref([]);
const scorePhones = ref([]);
const delete_scoring_phone = ref("");
const delete_regd_phone = ref("");
const delete_result_cat = ref("");
const action = ref("");
const spotPrizeList = ref([]);
const resultCats = ref([]);
const result_category = ref("");
var unsubscribe;
const selectedPlayerClass = (ph) => {
  var ret_class = "";
  // if (selectedPlayer.value == ph) {
  //   ret_class = "bogey border rounded";
  // }
  fourball.value.forEach((f) => {
    if (f.phone == ph) {
      ret_class = "bogey border rounded";
    }
  });
  return ret_class;
};
const generatePrizeList = () => {
  //console.log(prizeList.value, sorted_fourballs.value);
  spotPrizeList.value = [];
  prizeList.value.forEach((prz) => {
    console.log(prz.name);
    const przObj = { name: prz.name, winners: [] };
    //spotPrizeList.value[prz.name] = [];
    sorted_fourballs.value.forEach((f) => {
      f.prizes.forEach((pf) => {
        if (pf.prize == prz.name) {
          //console.log(pf.phone, fetchPlayerName(pf.phone));
          //spotPrizeList.value[prz.name].push(pf.phone);
          przObj.winners.push(pf.phone);
        }
      });
    });
    spotPrizeList.value.push(przObj);
  });
  console.log(spotPrizeList.value);
};

const fetchPlayerName = (phone) => {
  var name = "";
  for (const key in players.value) {
    if (players.value[key].phone == phone) {
      name = players.value[key].player;
    }
  }
  return name;
};
const setAction = (str) => {
  if (action.value != str) {
    action.value = str;
  } else {
    action.value = "";
  }
};
const selectDeleteScorePhone = (ph) => {
  if (delete_scoring_phone.value != ph) {
    delete_scoring_phone.value = ph;
  } else {
    delete_scoring_phone.value = "";
  }
};
const selectDeleteRegdPhone = (ph) => {
  if (delete_regd_phone.value != ph) {
    delete_regd_phone.value = ph;
  } else {
    delete_regd_phone.value = "";
  }
};
const selectDeleteResultCat = (ph) => {
  if (delete_result_cat.value != ph) {
    delete_result_cat.value = ph;
  } else {
    delete_result_cat.value = "";
  }
};
const selectDeletePrize = (prize) => {
  if (select_delete_prize.value != prize) {
    select_delete_prize.value = prize;
  } else {
    select_delete_prize.value = "";
  }
};
const reOrderFourball = async (fourballid, sno) => {
  //console.log(fourballid, sno);
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  await updateDoc(washingtonRef, {
    ["fourballs." + fourballid + ".sno"]: parseInt(sno),
    //fourballs: arrayUnion(fourballObj),
  });
};
const getNumberOfFourballs = () => {
  return sorted_fourballs.value.length;
};
const renumberFourball = (id) => {
  if (renumber_fourball.value != id) {
    renumber_fourball.value = id;
  } else {
    renumber_fourball.value = "";
  }
};
const specificFourBall = (player) => {
  if (shift_player_specific_fourball.value.phone != player.phone) {
    shift_player_specific_fourball.value = player;
  } else {
    shift_player_specific_fourball.value = {};
  }
};
const selectToDeleteFourball = (id) => {
  if (delete_fourball_id.value != id) {
    delete_fourball_id.value = id;
  } else {
    delete_fourball_id.value = "";
  }
};
const removeFromTempFourball = (p) => {
  let index = fourball.value.indexOf(p); // Find the index of the element
  if (index > -1) {
    fourball.value.splice(index, 1); // Remove the element using splice
  }
};
const sortedPlayers = () => {
  sorted_players.value = [];
  for (const key in players.value) {
    if (!checkIfAlreadyInFourball(players.value[key])) {
      sorted_players.value.push({ id: key, ...players.value[key] });
    }
  }

  sorted_players.value.sort((a, b) => {
    let fa = a.player.toLowerCase(),
      fb = b.player.toLowerCase();
    //console.log(fa, fb);
    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
};
const checkIfAlreadyInFourball = (player) => {
  var found = false;
  //console.log(player.player);
  // tournamentFourballs.value.forEach((value, key) => {
  //   console.log(value, key);
  // });
  for (const key in tournamentFourballs.value) {
    //console.log(key);
    tournamentFourballs.value[key].players.forEach((p) => {
      //console.log(p.phone);
      if (p.phone == player.phone) {
        found = true;
      }
    });
  }
  return found;
};
onMounted(() => {
  const q = query(
    collection(db, "tournaments"),
    where("admin", "==", auth.currentUser.uid)
  );
  unsubscribe = onSnapshot(q, (querySnapshot) => {
    const cities = [];
    querySnapshot.forEach((docy) => {
      cities.push({ id: docy.id, ...docy.data() });
    });
    tournaments.value = [];
    cities.forEach((c) => {
      tournaments.value.push(c);
    });
    //console.log("Current cities in CA: ", cities.join(", "));
  });
  // console.log(new XLSX());
});
onBeforeUnmount(() => {
  unsubscribe();
});
watchEffect(() => {
  id.value = store.selectedTournament;
  if (id.value) {
    tournaments.value.forEach((t) => {
      if (t.id == id.value) {
        players.value = t.players;
        tournamentName.value = t.name;
        shotgun.value = t.shotgun;
        prizeList.value = t.spotprize;
        tournamentFourballs.value = t.fourballs;
        regdPhones.value = t.registration;
        scorePhones.value = t.scoring;
        resultCats.value = t.resultcategory;
      }
    });
    sortedPlayers();

    sorted_fourballs.value = [];
    for (const key in tournamentFourballs.value) {
      sorted_fourballs.value.push({
        id: key,
        ...tournamentFourballs.value[key],
      });
    }
    sorted_fourballs.value.sort((a, b) => {
      let fa = a.sno;
      let fb = b.sno;
      //console.log(fa, fb);
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
  }
});

const selectThisTournament = (id) => {
  store.setTournament(id);
};
const clearTournament = () => {
  store.clearTournament();
  tournamentName.value = "";
  players.value = [];
  sorted_players.value = [];
  excelData.value = [];
  clearNames();
};
const addPlayer = async () => {
  if (!checkIfPlayerAlreadyExists()) {
    const washingtonRef = doc(db, "tournaments", store.selectedTournament);
    // await updateDoc(washingtonRef, {
    //   players: arrayUnion({
    //     player: playerName.value,
    //     phone: playerPhone.value,
    //     handicap: playerHandicap.value,
    //   }),
    // });
    const playerObj = {
      player: playerName.value,
      phone: playerPhone.value,
      handicap: playerHandicap.value,
      startHole: 1,
    };
    await updateDoc(washingtonRef, {
      // stages: stages,
      ["players." + nanoid()]: playerObj,
    });
    clearNames();
    error.value = "";
  } else {
    error.value = "Error: This player already exists";
    clearNames();
  }
};
const checkIfPlayerAlreadyExists = () => {
  var playerExists = false;

  for (const key in players.value) {
    if (players.value[key].phone == playerPhone.value) {
      playerExists = true;
    }
  }

  return playerExists;
};
const clearNames = () => {
  playerName.value = "";
  playerHandicap.value = "";
  playerPhone.value = "";
  selectedPlayer.value = "";
};
const selectPlayer = (player) => {
  if (selectedPlayer.value == player.phone) {
    selectedPlayer.value = "";
  } else {
    selectedPlayer.value = player.phone;
    if (
      !checkPlayerAlreadySelected(player.phone) &&
      fourball.value.length < 4
    ) {
      fourball.value.push({ player: player.player, phone: player.phone });
    }
  }
};
const checkPlayerAlreadySelected = (phone) => {
  var exists = false;
  fourball.value.forEach((p) => {
    if (p.phone == phone) {
      exists = true;
    }
  });
  return exists;
};
const removePlayer = async (p) => {
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  await updateDoc(washingtonRef, {
    ["players." + p]: deleteField(),
  });
};

const handleFileUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // Assuming the first sheet is the one you want to display
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Convert the sheet data to an array of objects
      const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      excelData.value = sheetData;
    };
    reader.readAsArrayBuffer(file);
  }
};
const removeFromUpload = (row) => {
  excelData.value = excelData.value.filter((item) => item != row);
  if (excelData.value.length == 1) {
    excelData.value = [];
    clearTournament();
  }
};
const addFromUpload = (row) => {
  //console.log("Adding =>", row[0]);
  playerName.value = row[0];
  playerPhone.value = row[1];
  playerHandicap.value = row[2];
  addPlayer();

  removeFromUpload(row);
};
const getShotgunClass = () => {
  var returnClass = "";
  if (shotgun.value) {
    returnClass = "btn-success";
  } else {
    returnClass = "btn-secondary";
  }
  return returnClass;
};
const toggleShotgun = async () => {
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  await updateDoc(washingtonRef, {
    shotgun: !shotgun.value,
  });
};
const setStartHole = async (id, hole) => {
  ///console.log(id, hole);
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  await updateDoc(washingtonRef, {
    ["players." + id + ".startHole"]: hole,
  });
};
const clearPrize = () => {
  prizeName.value = "";
  prizePhone.value = "";
};
const addPrize = async () => {
  ///console.log();
  const prizeObj = {
    name: prizeName.value,
    phone: prizePhone.value,
  };
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  await updateDoc(washingtonRef, {
    // ["spotprize." + nanoid()]: prizeObj,
    spotprize: arrayUnion(prizeObj),
  });
  clearPrize();
};
const deletePrize = async (prize) => {
  //console.log(prize);
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);

  await updateDoc(washingtonRef, {
    spotprize: arrayRemove(prize),
  });
};
const addFourBall = async () => {
  const fourballObj = {
    players: fourball.value,
    sno: largestFourballSerialNo(),
    prizes: [],
  };
  //console.log(fourballObj);
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  await updateDoc(washingtonRef, {
    ["fourballs." + nanoid()]: fourballObj,
    //fourballs: arrayUnion(fourballObj),
  });
  fourball.value = [];
};
const largestFourballSerialNo = () => {
  var sno = 0;
  for (const key in tournamentFourballs.value) {
    if (tournamentFourballs.value[key]) {
      if (tournamentFourballs.value[key].sno >= sno) {
        sno = tournamentFourballs.value[key].sno;
      }
    }
  }
  sno = sno + 1;
  return sno;
};
const deleteFourball = async (key) => {
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  await updateDoc(washingtonRef, {
    ["fourballs." + key]: deleteField(),
    //fourballs: arrayUnion(fourballObj),
  });
};
const getPlayerPrize = (player, key) => {
  var pvalue = "";
  tournamentFourballs.value[key].prizes.forEach((p) => {
    if (p.phone == player.phone) {
      pvalue = p.prize;
    }
  });
  return pvalue;
};
const selectToShift = (p) => {
  if (shiftPlayer.value != p) {
    shiftPlayer.value = p;
  } else {
    shiftPlayer.value = {};
  }
};
const getTargetFourballCount = (id) => {
  var count = 0;
  sorted_fourballs.value.forEach((fb) => {
    if (fb.id == id) {
      count = fb.players.length;
    }
  });
  return count;
};
const shiftToFourball = async (newFourballId, player, presentFourballId) => {
  // console.log(newFourballId, player);
  const targetFourBallCount = getTargetFourballCount(newFourballId);
  console.log("Add to fourball:", newFourballId, player);
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  if (targetFourBallCount <= 3) {
    deleteFromFourball(presentFourballId, player);
    await updateDoc(washingtonRef, {
      ["fourballs." + newFourballId + ".players"]: arrayUnion(player),
      //fourballs: arrayUnion(fourballObj),
    });
  } else {
    console.log("Fourball already has 4 players");
  }
};
const deleteFromFourball = async (fourballid, player) => {
  //console.log("Delete:", fourballid, player);
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  await updateDoc(washingtonRef, {
    ["fourballs." + fourballid + ".players"]: arrayRemove(player),
    //fourballs: arrayUnion(fourballObj),
  });
};
const shiftToSpecificFourball = async (fourball, player) => {
  const targetFourBallCount = getTargetFourballCount(fourball);
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  if (targetFourBallCount <= 3) {
    //deleteFromFourball(presentFourballId, player);
    await updateDoc(washingtonRef, {
      ["fourballs." + fourball + ".players"]: arrayUnion(player),
      //fourballs: arrayUnion(fourballObj),
    });
    removeFromTempFourball(player);
  } else {
    console.log("Fourball already has 4 players");
  }
};
const addRegdPhone = async () => {
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  await updateDoc(washingtonRef, {
    ["registration"]: arrayUnion(regd_phone.value),
    //fourballs: arrayUnion(fourballObj),
  });
  regd_phone.value = "";
};
const addScoringPhone = async () => {
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  await updateDoc(washingtonRef, {
    ["scoring"]: arrayUnion(score_phone.value),
    //fourballs: arrayUnion(fourballObj),
  });
  score_phone.value = "";
};
const addResultCategory = async () => {
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  await updateDoc(washingtonRef, {
    ["resultcategory"]: arrayUnion(result_category.value),
    //fourballs: arrayUnion(fourballObj),
  });
  result_category.value = "";
};
const deleteRegPhone = async (ph) => {
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  await updateDoc(washingtonRef, {
    ["registration"]: arrayRemove(ph),
    //fourballs: arrayUnion(fourballObj),
  });
};
const deleteScorePhone = async (ph) => {
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  await updateDoc(washingtonRef, {
    ["scoring"]: arrayRemove(ph),
    //fourballs: arrayUnion(fourballObj),
  });
};
const deleteResultCat = async (ph) => {
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  await updateDoc(washingtonRef, {
    ["resultcategory"]: arrayRemove(ph),
    //fourballs: arrayUnion(fourballObj),
  });
};
const checkIfScrambleTournament = () => {
  var isScramble = false;
  tournaments.value.forEach((t) => {
    if (t.id == store.selectedTournament) {
      if (t.scramble == true) {
        isScramble = true;
      }
    }
  });
  return isScramble;
};
</script>
<style scoped>
.dropdown-item {
  padding: 1px;
  font-size: 12px;
}
.smallList {
  padding: 1px;
  font-size: 12px;
}
.col-1 {
  padding: 0px;
}
.smalltext {
  font-size: 12px;
}
.bogey {
  background-color: lightgreen;
  border: 1px solid darkgreen;
}
</style>

<template>
  <div class="container">
    <Navbar></Navbar>
    <div class="row mt-2">
      <div class="col">
        <!-- {{ store.selectedTournament }} {{ id }} -->

        <button
          class="btn btn-sm float-start"
          :class="getShotgunClass()"
          @click="toggleShotgun()"
          v-if="store.selectedTournament"
        >
          Shotgun
        </button>

        <h5>
          {{ tournamentName }}
          <i
            class="fa fa-close float-right"
            v-if="store.selectedTournament"
            @click="clearTournament()"
          ></i>
        </h5>

        <div class="row mt-2" v-if="store.selectedTournament">
          <input
            type="text"
            class="form-control col"
            placeholder="Name"
            v-model="playerName"
          />
          <input
            type="text"
            class="form-control col"
            placeholder="Phone"
            v-model="playerPhone"
          />
          <input
            type="text"
            class="form-control col"
            placeholder="Handicap"
            v-model="playerHandicap"
          />
          <button class="btn btn-sm btn-success col" @click="addPlayer()">
            Add
          </button>
          <div class="bg-warning" v-if="error">{{ error }}</div>
        </div>
        <table class="table table-sm mt-2" v-if="store.selectedTournament">
          <tr>
            <td></td>
            <td class="border rounded">Name ({{ sorted_players.length }})</td>
            <td>
              <button
                class="btn btn-sm btn-primary mx-1"
                v-if="store.selectedTournament"
                @click="generateDummyPlayer()"
              >
                Dummy
              </button>
            </td>

            <td></td>
            <td class="border rounded">
              {{ tagCount() }}/{{ sorted_players.length }}
            </td>
            <td class="border rounded">
              {{ regdCount() }}/{{ sorted_players.length }}
            </td>
          </tr>
          <tr
            v-for="(t, index) in sorted_players"
            :key="t"
            class="border rounded"
          >
            <td class="smallList">{{ index + 1 }}</td>
            <td class="smallList">
              <i
                class="fa fa-close float-end align-middle"
                v-if="t.phone == selectedPlayer"
                @click="removePlayer(t.id)"
              ></i>
              <div
                @click="selectPlayer(t)"
                class="float-start"
                :class="selectedPlayerClass(t.phone)"
              >
                {{ t.player }}
              </div>
            </td>
            <td class="smallList">{{ t.phone }}</td>
            <td class="smallList">
              <EditHandicap
                :id="t.id"
                :handicap="t.handicap"
                :tournament="tournamentData"
              ></EditHandicap>
            </td>
            <td class="smallList">
              <i
                class="bi bi-tag"
                v-if="!t.bagtag"
                @click="toggleTag(t.id, t.bagtag)"
              ></i>
              <i
                class="bi bi-check"
                v-if="t.bagtag"
                @click="toggleTag(t.id, t.bagtag)"
              ></i>
            </td>
            <td class="smallList">
              <i
                class="bi bi-clipboard"
                v-if="!t.registration"
                @click="toggleRegistration(t.id, t.registration)"
              ></i>
              <i
                class="bi bi-check"
                v-if="t.registration"
                @click="toggleRegistration(t.id, t.registration)"
              ></i>
            </td>

            <td v-if="shotgun">
              <div class="dropdown">
                <button
                  class="btn btn-secondary dropdown-toggle btn-sm"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span v-if="t.startHole">{{ t.startHole }}</span>
                  <span v-else>Hole</span>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li
                    class="dropdown-item border rounded text-center"
                    v-for="n in 18"
                    :key="n"
                    @click="setStartHole(t.id, n)"
                  >
                    {{ n }}
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </table>

        <table
          v-if="excelData.length > 0 && store.selectedTournament"
          class="table table-sm"
        >
          <thead>
            <tr>
              <th></th>
              <th v-for="(cell, index) in excelData[0]" :key="index">
                {{ cell }}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, rowIndex) in excelData.slice(1)"
              :key="rowIndex"
              class="border rounded smallList"
            >
              <td>
                <i class="fa fa-close" @click="removeFromUpload(row)"></i>
              </td>
              <td v-for="(cell, cellIndex) in row" :key="cellIndex">
                {{ cell }}
              </td>
              <td>
                <button
                  class="btn btn-sm btn-success float-start"
                  @click="addFromUpload(row)"
                >
                  Add
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <input
          type="file"
          ref="file"
          @change="handleFileUpload"
          v-if="store.selectedTournament"
        />
      </div>
      <div class="col">
        <div class="row">
          <ul class="list-group">
            <li
              v-for="t in tournaments"
              class="list-group-item"
              :key="t.id"
              @click="selectThisTournament(t.id)"
            >
              {{ t.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { faker } from "@faker-js/faker";
import Navbar from "./AdminNavbar.vue";
import EditHandicap from "./EditHandicap.vue";
import { db, auth } from "../../firebase/config";
import {
  query,
  collection,
  where,
  onSnapshot,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  deleteField,
} from "firebase/firestore";
import { useStore } from "../../store/index";
import { onBeforeUnmount, onMounted, ref, watchEffect } from "vue";
import { customAlphabet } from "nanoid";
const nanoid = customAlphabet("123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ", 6);
var tournaments = ref([]);
var players = ref([]);
var shotgun = ref("");
const error = ref("");
var sorted_players = ref([]);
const playerName = ref("");
const playerHandicap = ref("");
const playerPhone = ref("");
const store = useStore();
const selectedPlayer = ref("");
const id = ref("");
const tournamentName = ref("");
const tournamentData = ref({});
let XLSX = require("xlsx");
const excelData = ref([]);

var unsubscribe;
const selectedPlayerClass = (ph) => {
  var ret_class = "";
  if (selectedPlayer.value == ph) {
    ret_class = "bogey border rounded";
  }
  return ret_class;
};
const sortedPlayers = () => {
  sorted_players.value = [];
  console.log("sorting these=>", players.value);
  for (const key in players.value) {
    sorted_players.value.push({ id: key, ...players.value[key] });
  }
  //console.log("before sort=>", tempArr);
  sorted_players.value.sort((a, b) => {
    let fa = a.player.toLowerCase(),
      fb = b.player.toLowerCase();
    console.log(fa, fb);
    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
  //console.log("sorted =>", tempArr);
  // sorted_players.value = tempArr;
  //return tempArr;
};
onMounted(() => {
  const q = query(
    collection(db, "tournaments"),
    where("admin", "==", auth.currentUser.uid)
  );
  unsubscribe = onSnapshot(q, (querySnapshot) => {
    const cities = [];
    querySnapshot.forEach((docy) => {
      cities.push({ id: docy.id, ...docy.data() });
    });
    tournaments.value = [];
    cities.forEach((c) => {
      tournaments.value.push(c);
    });
    //console.log("Current cities in CA: ", cities.join(", "));
  });
  // console.log(new XLSX());
});
onBeforeUnmount(() => {
  unsubscribe();
});
watchEffect(() => {
  id.value = store.selectedTournament;
  if (id.value) {
    tournaments.value.forEach((t) => {
      if (t.id == id.value) {
        players.value = t.players;
        tournamentName.value = t.name;
        shotgun.value = t.shotgun;
        tournamentData.value = { ...t };
      }
    });
    sortedPlayers();
  }
});

const selectThisTournament = (id) => {
  store.setTournament(id);
};
const clearTournament = () => {
  store.clearTournament();
  tournamentName.value = "";
  players.value = [];
  excelData.value = [];
  sorted_players.value = [];
  clearNames();
};
const addPlayer = async () => {
  if (!playerPhone.value) {
    playerPhone.value = faker.number.int({ min: 1111111111, max: 9999999999 });
  }
  if (!checkIfPlayerAlreadyExists()) {
    const washingtonRef = doc(db, "tournaments", store.selectedTournament);
    // await updateDoc(washingtonRef, {
    //   players: arrayUnion({
    //     player: playerName.value,
    //     phone: playerPhone.value,
    //     handicap: playerHandicap.value,
    //   }),
    // });
    const playerObj = {
      player: playerName.value,
      phone: playerPhone.value,
      handicap: playerHandicap.value,
      startHole: 1,
    };
    await updateDoc(washingtonRef, {
      // stages: stages,
      ["players." + nanoid()]: playerObj,
    });
    clearNames();
    error.value = "";
  } else {
    error.value = "Error: This player already exists";
    clearNames();
  }
};
const checkIfPlayerAlreadyExists = () => {
  var playerExists = false;

  // players.value.forEach((p) => {
  //   if (p.phone == playerPhone.value) {
  //     playerExists = true;
  //   }
  // });
  // players.value.map((p) => {
  //   if (p.phone == playerPhone.value) {
  //     playerExists = true;
  //   }
  // });
  for (const key in players.value) {
    //console.log(`${key}: ${players.value[key].player}`);
    if (players.value[key].phone == playerPhone.value) {
      playerExists = true;
    }
  }

  return playerExists;
};
const clearNames = () => {
  playerName.value = "";
  playerHandicap.value = "";
  playerPhone.value = "";
  selectedPlayer.value = "";
};
const selectPlayer = (player) => {
  if (selectedPlayer.value == player.phone) {
    selectedPlayer.value = "";
  } else {
    selectedPlayer.value = player.phone;
  }
};
const removePlayer = async (p) => {
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  await updateDoc(washingtonRef, {
    ["players." + p]: deleteField(),
  });
};

const handleFileUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // Assuming the first sheet is the one you want to display
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Convert the sheet data to an array of objects
      const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      excelData.value = sheetData;
    };
    reader.readAsArrayBuffer(file);
  }
};
const removeFromUpload = (row) => {
  excelData.value = excelData.value.filter((item) => item != row);
  if (excelData.value.length == 1) {
    excelData.value = [];
    clearTournament();
  }
};
const addFromUpload = (row) => {
  //console.log("Adding =>", row[0]);
  playerName.value = row[0];
  playerPhone.value = row[1];
  playerHandicap.value = row[2];
  addPlayer();

  removeFromUpload(row);
};
const getShotgunClass = () => {
  var returnClass = "";
  if (shotgun.value) {
    returnClass = "btn-success";
  } else {
    returnClass = "btn-secondary";
  }
  return returnClass;
};
const toggleShotgun = async () => {
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  await updateDoc(washingtonRef, {
    shotgun: !shotgun.value,
  });
};
const setStartHole = async (id, hole) => {
  ///console.log(id, hole);
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  await updateDoc(washingtonRef, {
    ["players." + id + ".startHole"]: hole,
  });
};
const toggleRegistration = async (id, value) => {
  //console.log(id);
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  await updateDoc(washingtonRef, {
    ["players." + id + ".registration"]: !value,
  });
};
const toggleTag = async (id, value) => {
  //console.log(id);
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  await updateDoc(washingtonRef, {
    ["players." + id + ".bagtag"]: !value,
  });
};
const regdCount = () => {
  var count = 0;
  sorted_players.value.forEach((p) => {
    if (p.registration) {
      count++;
    }
  });
  return count;
};
const tagCount = () => {
  var count = 0;
  sorted_players.value.forEach((p) => {
    if (p.bagtag) {
      count++;
    }
  });
  return count;
};
const generateDummyPlayer = () => {
  playerName.value = faker.person.fullName();
  playerPhone.value = faker.number.int({ min: 1111111111, max: 9999999999 });
  playerHandicap.value = 1;
  // console.log(playerName.value, playerPhone.value);
  addPlayer();
};
</script>
<style scoped>
.dropdown-item {
  padding: 1px;
  font-size: 12px;
}
.smallList {
  padding: 1px;
  font-size: 12px;
}
.bogey {
  background-color: lightgreen;
  border: 1px solid darkgreen;
}
</style>

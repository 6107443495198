<template>
  <div class="container">
    <div class="card mx-auto mt-5" style="width: 18rem">
      <div class="card-body">
        <h5 class="card-title">GolfTango!</h5>
        <p class="card-text text-muted">Play fast, repair divots.</p>
        <p class="card-text text-muted">Rake bunkers, fix pitch marks.</p>
      </div>

      <div class="card-body" v-if="false">
        <div v-if="!otpSent" class="mt-2">
          <div class="row d-flex justify-content-center">
            <input
              type="text"
              class="form-control text-center"
              placeholder="Enter phone number..."
              v-model.trim="phone"
              id="sign-in-button"
            />
          </div>

          <div class="row d-flex justify-content-center mt-1">
            <button class="btn btn-primary" style="width: 50%" @click="getOTP">
              Get OTP
            </button>
          </div>
        </div>
        <div v-if="otpSent">
          <div class="row d-flex justify-content-center">
            <input
              type="text"
              class="form-control text-center"
              placeholder="Enter OTP..."
              style="width: 50%"
              v-model.trim="otp"
            />
          </div>
          <div class="row d-flex justify-content-center mt-1">
            <button
              class="btn btn-success"
              style="width: 50%"
              @click="submitOTP"
            >
              Submit OTP
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-if="!otpSent">
      <div class="row d-flex justify-content-center">
        <input
          type="text"
          class="form-control"
          placeholder="Enter phone number..."
          style="width: 50%"
          v-model.trim="phone"
          id="sign-in-button"
        />
      </div>

      <div class="row d-flex justify-content-center">
        <button class="btn btn-primary" style="width: 25%" @click="getOTP">
          Get OTP
        </button>
      </div>
    </div>
    <div v-if="otpSent">
      <div class="row d-flex justify-content-center">
        <input
          type="text"
          class="form-control"
          placeholder="Enter OTP..."
          style="width: 50%"
          v-model.trim="otp"
        />
      </div>
      <div class="row d-flex justify-content-center">
        <button class="btn btn-success" style="width: 25%" @click="submitOTP">
          Submit OTP
        </button>
      </div>
    </div> -->
  </div>
</template>
<script setup>
import { auth } from "../firebase/config";
import { ref, onMounted } from "vue";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useRouter } from "vue-router";
const phone = ref("");
const otp = ref("");
const otpSent = ref(false);
var confResult = null;
const router = useRouter();
//const auth = getAuth();
var appVerifier = null;
onMounted(() => {
  appVerifier = new RecaptchaVerifier(
    "sign-in-button",
    {
      size: "invisible",
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        console.log(response);
      },
    },
    auth
  );
});

const getOTP = () => {
  console.log("getOTP() called");
  signInWithPhoneNumber(auth, "+91" + phone.value, appVerifier)
    .then((confirmationResult) => {
      console.log("SMS sent ");
      confResult = confirmationResult;
      otpSent.value = true;
    })
    .catch((error) => {
      // Error; SMS not sent
      console.log("sms not sent!", error.message);
      // ...
    });
};
const submitOTP = () => {
  confResult
    .confirm(otp.value)
    .then((result) => {
      //alert("Correct OTP entered", result);
      var user = result.user;
      console.log(user.uid);

      router.push("/home");
    })
    .catch((error) => {
      alert("Wrong OTP!!", error.message);
      console.log(error);
    });
};
</script>

<template>
  <div class="container">
    <Navbar></Navbar>
    <div class="row">
      <div class="col">
        <TournamentTemplate
          :tournamentid="selectedTournament"
        ></TournamentTemplate>
      </div>
      <div class="col">
        <div class="row">
          <ul class="list-group">
            <li
              v-for="t in tournaments"
              class="list-group-item"
              :key="t.id"
              @click="selectThisTournament(t.id)"
            >
              {{ t.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from "vue";
import { useStore } from "../../store/index";
import { auth, db } from "../../firebase/config";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import Navbar from "./AdminNavbar.vue";
import TournamentTemplate from "./TournamentTemplate.vue";
const selectedTournament = ref(null);

const store = useStore();
var unsubscribe;
const tournaments = ref([]);
onBeforeUnmount(() => {
  unsubscribe();
});
onMounted(() => {
  //console.log("mounted", auth.currentUser.uid);
  const q = query(
    collection(db, "tournaments"),
    where("admin", "==", auth.currentUser.uid)
  );
  unsubscribe = onSnapshot(q, (querySnapshot) => {
    const cities = [];
    querySnapshot.forEach((docy) => {
      cities.push({ id: docy.id, ...docy.data() });
    });
    tournaments.value = [];
    cities.forEach((c) => {
      tournaments.value.push(c);
    });
    //console.log("Current cities in CA: ", cities.join(", "));
  });
});
const selectThisTournament = (id) => {
  store.setTournament(id);
};
</script>

<template>
  <div class="row mt-1">
    <div class="col">
      <input type="text" class="form-control smalltxt" v-model.number="phone" />
    </div>
    <div class="col">
      <div class="dropdown">
        <button
          class="btn btn-secondary dropdown-toggle btn-sm"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Hole
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li
            class="dropdown-item smalltxt border"
            v-for="h in 18"
            :key="h"
            @click="selectHole(h)"
          >
            {{ h }}
          </li>
        </ul>
      </div>
    </div>
    <div class="col">
      <div class="dropdown">
        <button
          class="btn btn-secondary dropdown-toggle btn-sm"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Teams
        </button>
        <ul
          class="dropdown-menu smalltxt"
          aria-labelledby="dropdownMenuButton1"
        >
          <li
            class="dropdown-item smalltxt"
            v-for="h in sorted_fourballs"
            :key="h"
            @click="selectFourball(h.id)"
          >
            {{ h.sno }}
            <ul class="list-group smalltxt">
              <li
                class="list-group-item smalltxt"
                v-for="p in h.players"
                :key="p"
              >
                {{ p.player }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="col"></div>
    <div class="row" v-if="showAddButton">
      {{ phone }} - {{ otp }} {{ selectedFourball }}
      {{ selectedHole }}
      <button class="btn btn-sm btn-success" @click="addScorer()">Add</button>
    </div>
  </div>
  <div class="row">
    <ul class="list-group">
      <li
        class="list-group-item medtxt"
        v-for="g in tournamentData.scoringscramble"
        :key="g"
        @click="selectToDelete(g)"
      >
        <span>{{ g.phone }} [OTP: {{ g.otp }}]</span>
        <span v-if="g.hole"> - Hole: {{ g.hole }}</span
        ><span v-if="g.fourball"> - Group: {{ g.fourball }}</span>
        <i
          class="fa fa-trash-o float-end"
          v-if="toDelete == g"
          @click="deleteScorer(g)"
        ></i>
      </li>
    </ul>
  </div>
</template>
<script setup>
import { useStore } from "../../store/index";
import { onMounted, onBeforeUnmount, ref, computed, watchEffect } from "vue";
import { db } from "../../firebase/config";
import {
  doc,
  onSnapshot,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
const store = useStore();
const phone = ref("");
const otp = ref("");
const tournamentData = ref({});
const sorted_fourballs = ref([]);
const selectedFourball = ref("");
const selectedHole = ref("");
const toDelete = ref({});
const { customAlphabet } = require("nanoid");
const alphabet = "123456789";
const nanoid = customAlphabet(alphabet, 4);
//nanoid(); //=> "471951676815535658765"
const selectToDelete = (p) => {
  if (toDelete.value != p) {
    toDelete.value = p;
  } else {
    toDelete.value = {};
  }
};
const deleteScorer = async (obj) => {
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  await updateDoc(washingtonRef, {
    scoringscramble: arrayRemove(obj),
  });
};
const addScorer = async () => {
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  generateOTP();
  const obj = {
    phone: phone.value,
    otp: otp.value,
    hole: selectedHole.value,
    fourball: selectedFourball.value,
  };
  //console.log(obj);
  await updateDoc(washingtonRef, {
    scoringscramble: arrayUnion(obj),
  });
  phone.value = "";
  otp.value = "";
  selectedFourball.value = "";
  selectedHole.value = "";
};
const generateOTP = () => {
  otp.value = nanoid();
};
const showAddButton = computed(() => {
  var show = false;
  if (phone.value) {
    if (selectedHole.value || selectedFourball.value) {
      show = true;
    }
  }
  //otp.value = nanoid();
  return show;
});
const selectHole = (id) => {
  selectedFourball.value = "";
  if (selectedHole.value != id) {
    selectedHole.value = id;
  } else {
    selectedHole.value = "";
  }
};

const selectFourball = (id) => {
  selectedHole.value = "";
  if (selectedFourball.value != id) {
    selectedFourball.value = id;
  } else {
    selectedFourball.value = "";
  }
};
var unsub = () => {};
onBeforeUnmount(() => {
  unsub();
});
onMounted(() => {
  unsub = onSnapshot(
    doc(db, "tournaments", store.selectedTournament),
    (docy) => {
      //console.log("Current data: ", doc.data());
      tournamentData.value = { id: docy.id, ...docy.data() };
    }
  );
});
watchEffect(() => {
  sorted_fourballs.value = [];
  for (const key in tournamentData.value.fourballs) {
    sorted_fourballs.value.push({
      id: key,
      ...tournamentData.value.fourballs[key],
    });
  }
  sorted_fourballs.value.sort((a, b) => {
    let fa = a.sno;
    let fb = b.sno;
    //console.log(fa, fb);
    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
});
</script>
<style scoped>
.smalltxt {
  padding: 1px;
  font-size: 10px;
}
.medtxt {
  padding: 1px;
  font-size: 14px;
}
</style>

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyC9L2c3v3NIKebYJtEvApFKeKMMmEV9QdQ",
  authDomain: "bismad.firebaseapp.com",
  projectId: "bismad",
  storageBucket: "bismad.appspot.com",
  messagingSenderId: "233393460874",
  appId: "1:233393460874:web:955382acb35ec67f763f73",
};
initializeApp(firebaseConfig);
const db = getFirestore();
const auth = getAuth();

export { db, auth };

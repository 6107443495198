<template>
  <div class="container">
    <div class="row">
      <PlayerLogin></PlayerLogin>
    </div>
    <!-- <div class="row"><router-link to="/admin">Admin</router-link></div> -->
    <button class="btn btn-sm btn-primary mt-1" @click="toScores()">
      Scores
    </button>
  </div>
</template>

<script setup>
import PlayerLogin from "../views/Player.vue";
import { useRouter } from "vue-router";
const router = useRouter();
const toScores = () => {
  router.push("/scramble");
};
</script>

<template>
  <div class="container">
    <div
      class="card mt-5 mx-auto"
      style="width: 18rem"
      v-if="!tournaments.length"
    >
      <div class="card-body">
        <h5 class="card-title">Scramble Scorer Login</h5>
        <div class="row bg-warning rounded" v-if="error">{{ error }}</div>
        <div class="row mt-1">
          <div class="col">
            <input
              type="text"
              class="form-control"
              placeholder="Phone"
              v-model.number="phone"
            />
          </div>
        </div>
        <div class="row mt-1">
          <div class="col">
            <button class="btn btn-primary" @click="login()">Login</button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row mt-1"
      v-if="tournaments.length && selectedTournament.id == ''"
    >
      <div class="col">
        <i class="fa fa-close float-end" @click="forgetTournament()"></i>

        <ul class="list-group">
          <li class="list-group-item" v-for="t in tournaments" :key="t.id">
            <button class="btn btn-primary" @click="selectTournament(t)">
              {{ t.name }}
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="row mt-1" v-if="selectedTournament.id">
      <div class="col">
        <button
          :class="[
            'btn',
            'btn-sm',
            getClass('group') ? 'btn-primary' : 'btn-secondary',
          ]"
          @click="selectScoreFor('group')"
          v-if="false"
        >
          Group
        </button>
      </div>
      <div class="col">
        <i
          class="fa fa-close float-end"
          @click="forgetSelectedTournament()"
        ></i>
        Scramble scoring for: {{ selectedTournament.name }}
      </div>
      <div class="col">
        <button
          :class="[
            'btn',
            'btn-sm',
            getClass('hole') ? 'btn-primary' : 'btn-secondary',
          ]"
          @click="selectScoreFor('hole')"
          v-if="false"
        >
          Holes
        </button>
      </div>
    </div>
    <div class="row" v-if="selectedTournament.id">
      <GroupScoring
        v-if="scoresFor == 'group'"
        :cards="cards"
        :scorers="selectedTournament.scoringscramble"
        :phone="phone"
        :pars="selectedTournament.pars"
        :tournament="selectedTournament"
      ></GroupScoring>
      <HoleScoring
        v-if="scoresFor == 'hole'"
        :cards="cards"
        :scorers="selectedTournament.scoringscramble"
        :phone="phone"
        :pars="selectedTournament.pars"
        :tournament="selectedTournament"
      ></HoleScoring>
    </div>
  </div>
</template>
<script setup>
import GroupScoring from "./GroupScoring.vue";
import HoleScoring from "./HoleScoring.vue";
import { ref, watchEffect } from "vue";
import { useStore } from "../../store/index";
import {
  collection,
  getDocs,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase/config";
const phone = ref("");
const scoresFor = ref("hole");
const tournaments = ref([]);
const selectedTournament = ref({ id: "" });
const error = ref("");
const cards = ref([]);
const getClass = (val) => {
  var cl = false;
  if (val == scoresFor.value) {
    cl = true;
  }

  return cl;
};
const selectScoreFor = (val) => {
  if (scoresFor.value != val) {
    scoresFor.value = val;
  } else {
    scoresFor.value = "";
  }
};
var unsub_cards = () => {};
const selectTournament = (t) => {
  if (selectedTournament.value != t) {
    selectedTournament.value = t;
  } else {
    selectedTournament.value = t;
  }
};
const forgetTournament = () => {
  tournaments.value = [];
};
const forgetSelectedTournament = () => {
  selectedTournament.value = {};
  selectedTournament.value.id = "";
};
const login = async () => {
  error.value = "";
  if (!phone.value) {
    error.value = "Please enter valid phone number";
  }
  //   console.log(phone.value);
  const querySnapshot = await getDocs(collection(db, "tournaments"));
  querySnapshot.forEach((docy) => {
    // doc.data() is never undefined for query doc snapshots
    //console.log(docy.id, " => ", docy.data());
    if (docy.data().scramble) {
      docy.data().scoringscramble.forEach((d) => {
        if (d.phone == phone.value) {
          if (!tournamentAlreadyExists(docy.id)) {
            tournaments.value.push({ id: docy.id, ...docy.data() });
          }
        }
      });
    }
  });
};
const tournamentAlreadyExists = (id) => {
  var exists = false;
  tournaments.value.forEach((t) => {
    if (t.id == id) {
      exists = true;
    }
  });
  return exists;
};
watchEffect(() => {
  if (selectedTournament.value.id == "") {
    console.log("unsubscribing all cards...");
    unsub_cards();
  } else {
    const q = query(
      collection(db, "cards"),
      where("tournament", "==", selectedTournament.value.id)
    );
    unsub_cards = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((docy) => {
        cities.push({ id: docy.id, ...docy.data() });
      });
      cards.value = cities;
    });
  }
});
</script>

<template>
  <div class="container" v-if="playingTournament.id">
    <div class="row rounded mt-1">
      <div class="col-11 float-start">
        Hello {{ getPlayerName() }}, you are playing
        {{ playingTournament.name }}
      </div>
      <div class="col-1 float-end">
        <i class="fa fa-close" @click="store.clearPlayingTournament()"></i>
      </div>
    </div>
    <div class="row bg-warning text-center">{{ err }}</div>
    <div class="row rounded border">
      <div class="col">Hole</div>
      <div class="col">Index</div>
      <div class="col">Par</div>
      <div class="col">Score</div>
    </div>
    <div
      class="row rounded border"
      :class="isScoringHole(n)"
      v-for="n in 18"
      :key="n"
    >
      <div
        class="col"
        :class="startHoleClass(n)"
        @click="focusHole(n)"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        {{ n }}
      </div>

      <div
        class="col"
        @click="focusHole(n)"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        {{ playingTournament.index[n - 1] }}
      </div>
      <div
        class="col"
        @click="focusHole(n)"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        {{ playingTournament.pars[n - 1] }}
      </div>

      <div
        class="col"
        @click="focusHole(n)"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        <div
          v-if="card.scores[n - 1]"
          class="border rounded"
          :class="getStrokeColour(n)"
        >
          {{ card.scores[n - 1] }}
        </div>
      </div>

      <div class="scoringbuttons d-flex align-items-end" v-if="scoreHole == 19">
        <div class="col-3">
          <button
            class="birdie btn btn-sm"
            @click="addScore('birdie', scoreHole)"
          >
            <i class="fa fa-caret-left"></i> Birdie
          </button>
        </div>
        <div class="col-3">
          <button class="par btn btn-sm" @click="addScore('par', n)">
            Par
          </button>
        </div>
        <div class="col-3">
          <button class="bogey btn btn-sm" @click="addScore('bogey', n)">
            Bogey
          </button>
        </div>
        <div class="col-3">
          <button class="double btn btn-sm" @click="addScore('double', n)">
            Double <i class="fa fa-caret-right"></i>
          </button>
        </div>
        <!-- <div class="col-3">
          <i
            class="fa fa-close"
            style="font-size: 30px; color: grey"
            @click="scoreHole = null"
          ></i>
        </div> -->
      </div>
    </div>
    <div class="row">
      <div class="col"></div>
      <div class="col">
        <button
          class="btn btn-primary btn-sm"
          v-if="!card.submitted"
          @click="dummyScore()"
        >
          Dummy
        </button>
        <button
          class="btn btn-danger btn-sm ml-1"
          v-if="!card.submitted"
          @click="clearScore()"
        >
          Clear
        </button>
      </div>
      <div class="col">
        <button
          v-if="checkIfCardReadyToSubmit()"
          class="btn btn-success"
          @click="submitCard()"
        >
          Submit
        </button>
        <div v-if="card.submitted" class="fw-bold text-success">Submitted!</div>
      </div>
      <div class="col">{{ totalScore().total }} ({{ totalScore().topar }})</div>
    </div>
  </div>
  <!--MODAL START-->
  <div
    class="modal"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    v-if="card.id"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title" id="exampleModalLabel">
            Hole: {{ scoreHole }}, Par:
            {{ playingTournament.pars[scoreHole - 1] }}
          </h2>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col">
              <div class="card" data-bs-dismiss="modal">
                <div class="card-body" @click="setStroke(1, scoreHole)">
                  <h3>1</h3>
                  <div>{{ getModalStrokeName(1) }}</div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card" data-bs-dismiss="modal">
                <div
                  class="card-body rounded"
                  @click="setStroke(2, scoreHole)"
                  :class="getModalStrokeClass(2)"
                >
                  <h3>2</h3>
                  <div>{{ getModalStrokeName(2) }}</div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card" data-bs-dismiss="modal">
                <div
                  class="card-body rounded"
                  @click="setStroke(3, scoreHole)"
                  :class="getModalStrokeClass(3)"
                >
                  <h3>3</h3>
                  <div>{{ getModalStrokeName(3) }}</div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card" data-bs-dismiss="modal">
                <div
                  class="card-body rounded"
                  @click="setStroke(4, scoreHole)"
                  :class="getModalStrokeClass(4)"
                >
                  <h3>4</h3>
                  <div>{{ getModalStrokeName(4) }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card" data-bs-dismiss="modal">
                <div
                  class="card-body rounded"
                  @click="setStroke(5, scoreHole)"
                  :class="getModalStrokeClass(5)"
                >
                  <h3>5</h3>
                  <div>{{ getModalStrokeName(5) }}</div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card" data-bs-dismiss="modal">
                <div
                  class="card-body rounded"
                  @click="setStroke(6, scoreHole)"
                  :class="getModalStrokeClass(6)"
                >
                  <h3>6</h3>
                  <div>{{ getModalStrokeName(6) }}</div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card" data-bs-dismiss="modal">
                <div
                  class="card-body rounded"
                  @click="setStroke(7, scoreHole)"
                  :class="getModalStrokeClass(7)"
                >
                  <h3>7</h3>
                  <div>{{ getModalStrokeName(7) }}</div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card" data-bs-dismiss="modal">
                <div
                  class="card-body rounded"
                  @click="setStroke(8, scoreHole)"
                  :class="getModalStrokeClass(8)"
                >
                  <h3>8</h3>
                  <div>{{ getModalStrokeName(8) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary float-start"
            data-bs-dismiss="modal"
            @click="setStroke(0, scoreHole)"
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--MODAL END-->
</template>
<script setup>
import { onBeforeUnmount, ref, watchEffect } from "vue";
import {
  doc,
  // getDoc,
  addDoc,
  getCountFromServer,
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db, auth } from "../firebase/config";
import { useStore } from "../store/index";
const store = useStore();
const scoreHole = ref(null);
const playingTournament = ref({});
const card = ref({});
const err = ref("");
const scores = ref([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
const startHole = ref("");
const playingSequence = ref([]);
//const playingCard = ref({});
var unsub;
var unsub_card;
const getStartHole = () => {
  //console.log("getting start hole.......", playingTournament.value.players);
  //let retunValue = false;
  for (const key in playingTournament.value.players) {
    //console.log(playingTournament.value.players[key].phone);
    if (
      "+91" + playingTournament.value.players[key].phone ==
      auth.currentUser.phoneNumber
    ) {
      startHole.value = playingTournament.value.players[key].startHole;
    }
  }
  setHoleSequence();
};
const fetchCardForThisTournament = async () => {
  const last10Digits = auth.currentUser.phoneNumber.slice(-10);
  //console.log(last10Digits);
  const coll = collection(db, "cards");
  const q = query(
    coll,
    where("tournament", "==", store.playingTournament),
    //where("playerid", "==", auth.currentUser.uid)
    where("phone", "==", last10Digits)
  );
  const snapshot = await getCountFromServer(q);
  //console.log("count: ", snapshot.data().count);
  if (snapshot.data().count) {
    //console.log("card already exists - fetch");
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((docd) => {
      unsub_card = onSnapshot(doc(db, "cards", docd.id), (docy) => {
        card.value = { id: docy.id, ...docy.data() };
      });
    });
  } else {
    // console.log("insert new card");
    const newCard = {
      //playerid: auth.currentUser.uid,
      tournament: store.playingTournament,
      scores: scores.value,
      //phone: auth.currentUser.phoneNumber,
      phone: last10Digits,
      submitted: false,
    };
    const docRef = await addDoc(collection(db, "cards"), newCard);
    if (docRef.id) {
      console.log("Document written with ID: ", docRef.id);
      unsub_card = onSnapshot(doc(db, "cards", docRef.id), (docy) => {
        card.value = { id: docy.id, ...docy.data() };
      });
    }
  }
  getStartHole();
};
const getPlayerName = () => {
  var name = "";
  const last10Digits = auth.currentUser.phoneNumber.slice(-10);
  for (const key in playingTournament.value.players) {
    if (playingTournament.value.players[key].phone == last10Digits) {
      name = playingTournament.value.players[key].player;
    }
  }
  return name;
};
watchEffect(async () => {
  const docRef = doc(db, "tournaments", store.playingTournament);
  unsub = onSnapshot(docRef, (docy) => {
    playingTournament.value = { id: docy.id, ...docy.data() };
  });
  if (store.playingTournament) {
    fetchCardForThisTournament();
  }
  //getStartHole();
});
onBeforeUnmount(() => {
  unsub();
  unsub_card();
});
// const course = [
//   { par: 4, score: 0 },
//   { par: 5, score: 0 },
//   { par: 3, score: 0 },
//   { par: 4, score: 0 },
//   { par: 4, score: 0 },
//   { par: 4, score: 0 },
//   { par: 5, score: 0 },
//   { par: 3, score: 0 },
//   { par: 4, score: 0 },
//   { par: 4, score: 0 },
//   { par: 3, score: 0 },
//   { par: 4, score: 0 },
//   { par: 5, score: 0 },
//   { par: 3, score: 0 },
//   { par: 4, score: 0 },
//   { par: 5, score: 0 },
//   { par: 4, score: 0 },
//   { par: 4, score: 0 },
// ];

const totalScore = () => {
  let total = 0;
  let toPar = 0;
  card.value.scores.forEach((element, index) => {
    total = total + element;
    if (element) {
      //toPar = toPar + course[index].par;
      toPar = toPar + playingTournament.value.pars[index];
    }
  });
  return { total: total, topar: total - toPar };
};

const addScore = async (score, hole) => {
  const cardRef = doc(db, "cards", card.value.id);
  const tempScore = card.value.scores;
  if (score == "birdie") {
    if (!tempScore[hole - 1]) {
      if (tempScore[hole - 1] <= playingTournament.value.pars[hole - 1]) {
        tempScore[hole - 1]--;
      } else {
        tempScore[hole - 1] = playingTournament.value.pars[hole - 1] - 1;
      }
    } else {
      if (tempScore[hole - 1] >= 1) {
        tempScore[hole - 1]--;
      }
    }
  }
  if (score == "par") {
    //scores.value[hole - 1] = course[hole - 1].par;
    tempScore[hole - 1] = playingTournament.value.pars[hole - 1];
  }
  if (score == "bogey") {
    tempScore[hole - 1] = playingTournament.value.pars[hole - 1] + 1;
    // if (!scores.value[hole - 1]) {
    //   scores.value[hole - 1] = course[hole - 1].par + 1;
    //   tempScore[hole - 1] = playingTournament.value.pars[hole - 1] + 1;
    // } else {
    //   scores.value[hole - 1]++;
    //   tempScore[hole - 1]++;
    // }
  }
  if (score == "double") {
    if (tempScore[hole - 1] < playingTournament.value.pars[hole - 1] + 2) {
      //scores.value[hole - 1] = course[hole - 1].par + 1;
      tempScore[hole - 1] = playingTournament.value.pars[hole - 1] + 2;
    } else {
      //scores.value[hole - 1]++;
      tempScore[hole - 1]++;
    }
  }
  await updateDoc(cardRef, {
    scores: tempScore,
  });
  clearScoringButtons();
};
const clearScoringButtons = () => {
  setTimeout(() => {
    scoreHole.value = null;
  }, 10);
};
const setHoleSequence = () => {
  //const holeSequence = [];
  //console.log("StartHole", startHole.value % 18);
  for (var i = 0; i < 18; i++) {
    var playHole = (startHole.value + i) % 18;
    if (playHole == 0) {
      playHole = 18;
    }
    playingSequence.value.push(playHole);
  }
  //console.log(holeSequence);
};
const focusHole = (hole) => {
  let found = false;
  //console.log("CARD =>", card.value.scores);
  //console.log("SEQUENCE =>", playingSequence.value);
  var counter = 0;
  while (!found) {
    let h = playingSequence.value[counter];
    if (card.value.scores[hole - 1]) {
      scoreHole.value = hole;
      found = true;
    }
    if (!card.value.scores[h - 1]) {
      scoreHole.value = h;
      found = true;
    }
    counter++;
    if (counter > 18) {
      found = true;
    }
  }
  // playingSequence.value.forEach((seq, index) => {
  //   if (!found) {
  //     if (card.value.scores[seq - 1] == 0) {
  //       scoreHole.value = seq - 1;
  //       found = true;
  //     }
  //   }
  // });
  // const holeSequence = [];
  // setHoleSequence();
  // let found = false;
  // var counter = 0;
  // while (!found) {
  //   if (card.value.scores[hole - 1]) {
  //     scoreHole.value = hole;
  //     found = true;
  //   }
  //   if (!card.value.scores[counter]) {
  //     scoreHole.value = counter + 1;
  //     found = true;
  //   }
  //   counter++;
  //   if (counter > 18) {
  //     found = true;
  //   }
  // }
  //console.log(hole);
  // if (card.value.scores[hole - 1]) {
  //   scoreHole.value = hole;
  // } else {
  //   var numberOfHolesPlayed = 0;
  //   card.value.scores.forEach((h) => {
  //     if (h > 0) {
  //       numberOfHolesPlayed++;
  //     }
  //   });
  //   scoreHole.value = playingSequence.value[numberOfHolesPlayed];
  // }

  // playingSequence.value.forEach((seq) => {
  //   if (!card.value.scores[seq - 1] && seq == hole + 1) {
  //     if (seq == 18) {
  //       scoreHole.value = 0;
  //     } else {
  //       scoreHole.value = seq + 1;
  //     }
  //   }
  // });

  if (card.value.submitted) {
    scoreHole.value = null;
    err.value = "Card already submitted!";
  }
};
const dummyScore = () => {
  for (var i = 0; i < 19; i++) {
    // scores.value[i] = getRandomInt(2, 8);
    setStroke(getRandomInt(2, 8), i);
  }
};
const clearScore = () => {
  for (var i = 0; i < 19; i++) {
    // scores.value[i] = getRandomInt(2, 8);
    setStroke(0, i);
  }
};
function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
const isScoringHole = (hole) => {
  if (hole == scoreHole.value) {
    return "scoringbuttons";
  }
};
const isParStroke = (stroke, hole) => {
  if (stroke == playingTournament.value.pars[hole - 1]) {
    return "bg-primary";
  }
};
const setStroke = async (stroke, hole) => {
  //console.log(stroke, hole, playingTournament.value.pars[hole - 1] + 1);
  const cardRef = doc(db, "cards", card.value.id);
  const tempScore = card.value.scores;
  //tempScore[hole - 1] = stroke;
  if (
    stroke == playingTournament.value.pars[hole - 1] + 2 &&
    tempScore[hole - 1] >= stroke
  ) {
    tempScore[hole - 1]++;
  } else {
    tempScore[hole - 1] = stroke;
  }
  await updateDoc(cardRef, {
    scores: tempScore,
  });
  clearScoringButtons();
};
const getStrokeColour = (hole) => {
  if (card.value.scores[hole - 1] > playingTournament.value.pars[hole - 1]) {
    return "bogey";
  }
  if (card.value.scores[hole - 1] < playingTournament.value.pars[hole - 1]) {
    return "birdie";
  }
};
const submitCard = async () => {
  console.log(card.value.id);
  const washingtonRef = doc(db, "cards", card.value.id);

  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    submitted: true,
  });
};
const checkIfCardReadyToSubmit = () => {
  var allHolesComplete = true;
  card.value.scores.forEach((s) => {
    if (!s) {
      allHolesComplete = false;
    }
  });
  if (card.value.submitted) {
    allHolesComplete = false;
  }
  return allHolesComplete;
};
const startHoleClass = (h) => {
  if (startHole.value == h) return "starthole";
};
const getModalStrokeName = (stroke) => {
  let str = "";
  if (scoreHole.value) {
    var retunValue;
    const par = playingTournament.value.pars[scoreHole.value - 1];
    if (stroke == 1) {
      str = "Ace!";
    }
    if (par - stroke == 2) {
      str = "Eagle";
      if (par == 3) {
        str = "Ace!";
      }
    }
    if (par - stroke == 1) {
      str = "Birdie";
    }
    if (par == stroke) {
      str = "Par";
    }
    if (stroke - par == 1) {
      str = "Bogey";
    }
    if (stroke - par == 2) {
      str = "D.Bogey";
    }
    if (stroke - par >= 3) {
      str = "Oops!";
    }
  }
  str = "";
  return str;
};
const getModalStrokeClass = (stroke) => {
  let str = "";
  if (scoreHole.value) {
    var retunValue;
    const par = playingTournament.value.pars[scoreHole.value - 1];

    if (par - stroke == 1) {
      str = "birdie";
    }
    if (par == stroke) {
      str = "par";
    }
    if (stroke - par == 1) {
      str = "bogey";
    }
  }
  return str;
};
</script>
<style scoped>
.row {
  border: 1px;
  margin: 2px;
}

.scoringbuttons {
  /* border: 1px dotted green; */
  /* background-color: #ffff8f; */
  background-color: #ecffdc;
}
.bi-pen-fill {
  color: blue;
}
.birdie {
  background-color: #ffe6ea;
  border: 1px solid red;
}
.par {
  background-color: lightblue;
  border: 1px solid darkblue;
}
.bogey {
  background-color: lightgreen;
  border: 1px solid darkgreen;
}
.double {
  background-color: #f33a6a;
  border: 1px solid red;
}
.starthole {
  background-color: #b4cfec;
}
/* .fade {
  transition: opacity 2000ms;
} */
</style>

<template>
  <div class="card">
    <div class="card-body">
      <div class="row bg-warning" v-if="error">{{ error }}</div>
      <div class="row">
        <div class="col">
          <!-- {{ store.selectedTournament }} -->
          <input
            type="text"
            class="form-control"
            placeholder="Tournament Name"
            v-model.trim="name"
          />
        </div>
        <div class="col">
          <input type="date" class="form-control" v-model.trim="date" />
        </div>
        <div class="col">
          <button
            class="btn btn-sm"
            :class="getShotgunClass()"
            @click="toggleShotgun()"
          >
            Shotgun
          </button>
        </div>
        <div class="col">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              :check="scramble"
              @change="toggleScramble()"
            />
            <label class="form-check-label" for="flexSwitchCheckChecked"
              >Scramble</label
            >
          </div>
        </div>
      </div>
      <!-- {{ hidden }} -->
      <div class="row border rounded">
        <div class="col mt-1 mb-1">
          T Handicap = (HiddenScore X
          <i
            class="fa fa-angle-up border rounded btn-sm btn btn-primary"
            @click="incrementMultiplier('first')"
          ></i>
          {{ multipliers.first.toFixed(1) }}
          <i
            class="fa fa-angle-down border rounded btn-sm btn btn-danger"
            @click="decrementMultiplier('first')"
          ></i
          >- CoursePar) X
          <i
            class="fa fa-angle-up border rounded btn btn-sm btn-primary"
            @click="incrementMultiplier('second')"
          ></i>
          {{ multipliers.second.toFixed(1) }}
          <i
            class="fa fa-angle-down border rounded btn btn-sm btn-danger"
            @click="decrementMultiplier('second')"
          ></i>
        </div>
      </div>
      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">Hole</th>
            <th scope="col">Stroke Index</th>
            <th scope="col">Par ({{ totalStrokes() }})</th>
            <th scope="col">Secret</th>
          </tr>
        </thead>
        <tbody v-if="name">
          <tr v-for="h in 18" :key="h" :class="checkHiddenHole(h)">
            <th scope="row">{{ h }}</th>
            <td>
              <i class="fa fa-angle-up float-start"></i>{{ h
              }}<i class="fa fa-angle-down float-end"></i>
            </td>
            <td>
              <i class="fa fa-angle-up float-start" @click="addPar(h)"></i
              >{{ par[h - 1]
              }}<i
                class="fa fa-angle-down float-end"
                @click="substractPar(h)"
              ></i>
            </td>
            <td>
              <button
                class="btn btn-sm btn-danger"
                v-if="checkHiddenHole(h)"
                @click="toggleHidden(h)"
              >
                Remove
              </button>
              <button
                class="btn btn-sm btn-primary"
                v-else
                @click="toggleHidden(h)"
              >
                Select
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <button
        class="btn btn-danger btn-sm float-start"
        @click="clearTournament()"
      >
        Cancel
      </button>
      <button class="btn btn-primary btn-sm float-end" @click="update()">
        Update
      </button>
    </div>
  </div>
</template>
<script setup>
import { onBeforeUnmount, onMounted, ref, watchEffect } from "vue";
import { useStore } from "../../store/index";
import { db, auth } from "../../firebase/config";
import {
  addDoc,
  collection,
  getDoc,
  doc,
  updateDoc,
  increment,
  onSnapshot,
  query,
  where,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
const par = ref([4, 5, 3, 4, 4, 4, 5, 3, 4, 4, 3, 4, 5, 3, 4, 5, 4, 4]);
const index = ref([
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
]);
const hidden = ref([]);
const name = ref("");
const date = ref("");
const id = ref("");
const shotgun = ref("");
const scramble = ref("");
const toggleScramble = async () => {
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  await updateDoc(washingtonRef, {
    scramble: !scramble.value,
  });
};
const multipliers = ref({
  first: 1.0,
  second: 1.0,
});
const store = useStore();
const error = ref("");
let unsub;
onBeforeUnmount(() => {
  unsub();
});
onMounted(() => {});
const toggleHidden = async (hole) => {
  console.log(multipliers.value);
  if (multipliers.value.first < 3) {
    if (!hidden.value.includes(hole) && hidden.value.length < 12) {
      hidden.value.push(hole);
    } else {
      hidden.value = hidden.value.filter((item) => {
        return item !== hole;
      });
    }
  } else {
    if (!hidden.value.includes(hole) && hidden.value.length < 6) {
      hidden.value.push(hole);
    } else {
      hidden.value = hidden.value.filter((item) => {
        return item !== hole;
      });
    }
  }

  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  await updateDoc(washingtonRef, {
    hidden: hidden.value,
  });
  //updateAllCardsInThisTournamentForScoreCardReactivity();
};
const checkHiddenHole = (hole) => {
  if (hidden.value.includes(hole)) return "bggreen";
};
const fetchTournament = async (id) => {
  //console.log(id);
  const docRef = doc(db, "tournaments", id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    //console.log("Document data:", docSnap.data());
    par.value = [];
    index.value = [];
    name.value = docSnap.data().name;
    date.value = docSnap.data().date;
    par.value = docSnap.data().pars;
    index.value = docSnap.data().index;
    hidden.value = docSnap.data().hidden;
    multipliers.value = docSnap.data().multipliers;
    shotgun.value = docSnap.data().shotgun;
    scramble.value = docSnap.data().scramble;
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
};
watchEffect(() => {
  id.value = store.selectedTournament;
  if (id.value) {
    console.log("fetch this tournament: ", id.value);
    fetchTournament(id.value);
    //resetID.value = false;
    unsub = onSnapshot(
      doc(db, "tournaments", store.selectedTournament),
      (docy) => {
        console.log("Current data: ", docy.data());
        multipliers.value = { ...docy.data().multipliers };
        shotgun.value = docy.data().shotgun;
        scramble.value = docy.data().scramble;
      }
    );
  } else {
    console.log("Cleared");
  }
  if (scramble.value) {
    document.getElementById("flexSwitchCheckChecked").checked = scramble.value;
  }
});
const clearTournament = () => {
  store.clearTournament();
  error.value = "";
  name.value = "";
  date.value = "";
  error.value = "";
  hidden.value = [];
};
const totalStrokes = () => {
  var total = 0;
  par.value.forEach((i) => {
    total = total + i;
  });
  return total;
};
const addPar = (hole) => {
  par.value[hole - 1]++;
  if (par.value[hole - 1] > 5) {
    par.value[hole - 1] = 5;
  }
};
const substractPar = (hole) => {
  par.value[hole - 1]--;
  if (par.value[hole - 1] < 3) {
    par.value[hole - 1] = 3;
  }
};
const toggleShotgun = async () => {
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  await updateDoc(washingtonRef, {
    shotgun: !shotgun.value,
  });
};
const getShotgunClass = () => {
  var returnClass = "";
  if (shotgun.value) {
    returnClass = "btn-success";
  } else {
    returnClass = "btn-secondary";
  }
  return returnClass;
};
const addTournament = async () => {
  error.value = "";
  if (name.value && date.value) {
    await addDoc(collection(db, "tournaments"), {
      admin: auth.currentUser.uid,
      date: date.value,
      fourballs: {},
      hidden: [],
      index: index.value,
      multipliers: { first: 1.5, second: 0.8 },
      name: name.value,
      pars: par.value,
      players: {},
      publish: false,
      registration: [],
      resultcategory: [],
      scoring: [],
      shotgun: false,
      spotprize: [],
    });
    name.value = "";
    date.value = "";
    error.value = "";
  } else {
    error.value = "Name or Date cannot be left blank";
  }
};
const updateTournament = async (id) => {
  //   console.log("Update tournament:", id);
  if (name.value && date.value) {
    const washingtonRef = doc(db, "tournaments", id);
    await updateDoc(washingtonRef, {
      pars: par.value,
      index: index.value,
      date: date.value,
      hidden: hidden.value,
    });
    clearTournament();
  } else {
    error.value = "Name or Date cannot be left blank";
  }
};
const update = () => {
  if (store.selectedTournament) {
    updateTournament(store.selectedTournament);
    updateAllCardsInThisTournamentForScoreCardReactivity();
  } else {
    addTournament();
  }
};
const incrementMultiplier = async (multi) => {
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  if (multi == "first") {
    await updateDoc(washingtonRef, {
      "multipliers.first": increment(0.1),
    });
  }
  if (multi == "second") {
    await updateDoc(washingtonRef, {
      "multipliers.second": increment(0.1),
    });
  }

  updateAllCardsInThisTournamentForScoreCardReactivity();
};
const decrementMultiplier = async (multi) => {
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  if (multi == "first") {
    await updateDoc(washingtonRef, {
      "multipliers.first": increment(-0.1),
    });
  }
  if (multi == "second") {
    await updateDoc(washingtonRef, {
      "multipliers.second": increment(-0.1),
    });
  }
  updateAllCardsInThisTournamentForScoreCardReactivity();
};
const updateAllCardsInThisTournamentForScoreCardReactivity = async () => {
  const q = query(
    collection(db, "cards"),
    where("tournament", "==", store.selectedTournament)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach(async (dock) => {
    //console.log("updating all cards of this tournament");
    const cardRef = doc(db, "cards", dock.id);
    await updateDoc(cardRef, {
      tournamentUpdated: serverTimestamp(),
    });
  });
};
</script>
<style scoped>
.bggreen {
  background-color: #defde0;
}
.tinybtn {
  padding: 2px;
}
</style>

<template>
  <div class="container">
    <div v-if="!phoneOK">
      <div class="card mx-auto mt-5" style="width: 18rem">
        <div class="card-body">
          <h5 class="card-title">Scoring</h5>
          <div class="row">
            <input
              type="text"
              class="form-control"
              placeholder="Phone"
              v-model.trim="phone"
            />
          </div>
          <div class="row mt-1">
            <button class="btn btn-primary" @click="submitPhone()">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="phoneOK && !tournamentData.id" class="mt-1">
      <div v-for="t in tournamentList" :key="t.id">
        <button class="btn btn-primary mr-1" @click="selectTournament(t)">
          {{ t.name }}
        </button>
      </div>
    </div>
    <div v-if="tournamentData.id">
      <i class="fa fa-close float-end" @click="cancelTournament()"></i>
      <table class="table table-hover table-sm">
        <tr v-for="p in sorted_players" :key="p.id">
          <!-- <td class="border">{{ card.id }}</td> -->
          <td class="text-start">
            {{ p.player }}
          </td>
          <td
            v-for="hole in 9"
            :key="hole"
            @keyup="updateStroke(hole, p.phone, $event.target.value)"
            class="noPadding"
          >
            <input
              type="text"
              class="form-control text-center noPadding"
              :value="getValue(hole, p.phone)"
              :class="getStrokeClass(hole, p.phone)"
            />
          </td>
          <td class="bg-warning rounded border">{{ frontNine(p.phone) }}</td>
          <td
            v-for="hole in [10, 11, 12, 13, 14, 15, 16, 17, 18]"
            :key="hole"
            @keyup="updateStroke(hole, p.phone, $event.target.value)"
            class="noPadding"
          >
            <input
              type="text"
              class="form-control text-center noPadding"
              :value="getValue(hole, p.phone)"
              :class="getStrokeClass(hole, p.phone)"
            />
          </td>
          <td class="bg-warning rounded border">{{ backNine(p.phone) }}</td>
          <td>
            <input
              type="text"
              class="form-control bg-warning text-center noPadding"
              :value="cardTotal(p.phone)"
              readonly
            />
          </td>
          <td>
            <input
              type="text"
              class="form-control birdie text-center noPadding"
              :value="getBirdies(p.phone)"
              readonly
            />
          </td>
          <td>
            <input
              type="text"
              class="form-control par text-center noPadding"
              :value="getPars(p.phone)"
              readonly
            />
          </td>
          <td>
            <input
              type="text"
              class="form-control bogey text-center noPadding"
              :value="getBogeys(p.phone)"
              readonly
            />
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script setup>
import { ref, watchEffect, onBeforeUnmount } from "vue";
import {
  collection,
  getDocs,
  query,
  where,
  onSnapshot,
  arrayUnion,
  arrayRemove,
  updateDoc,
  doc,
  getCountFromServer,
} from "firebase/firestore";
import { db } from "../../firebase/config";
const phone = ref("");
const phoneOK = ref(false);
const tournamentList = ref([]);
const tournamentData = ref({});
const sorted_cards = ref([]);
const sorted_players = ref([]);
const cards_exist = ref([]);
var unsubscribe;

var unsub;

onBeforeUnmount(() => {
  console.log("unmounting...");
  unsub();
  unsubscribe();
});
const submitPhone = async () => {
  //console.log(phone.value);
  const querySnapshot = await getDocs(collection(db, "tournaments"));
  querySnapshot.forEach((docy) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(docy.id, " => ", docy.data());
    if (checkIfPhonePresent(docy.data().scoring)) {
      tournamentList.value.push({ id: docy.id, ...docy.data() });
      phoneOK.value = true;
    }
  });
};
const selectTournament = (tournament) => {
  unsub = onSnapshot(doc(db, "tournaments", tournament.id), (docy) => {
    tournamentData.value = { id: docy.id, ...docy.data() };
  });
};
watchEffect(() => {
  if (tournamentData.value.id) {
    const q = query(
      collection(db, "cards"),
      where("tournament", "==", tournamentData.value.id)
    );
    unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((docy) => {
        cities.push({ id: docy.id, ...docy.data() });
      });
      //allCards.value = cities;
      sorted_cards.value = cities;
    });
    //addPlayerNameToCard();
    sorted_players.value = [];
    fetchSortedPlayers();
  }
});
const getPlayerName = (phone) => {
  var name = "";
  for (const key in tournamentData.value.players) {
    if (tournamentData.value.players[key].phone == phone) {
      name == tournamentData.value.players[key].player;
    }
  }
  return name;
};

const checkIfPhonePresent = (prizearr) => {
  var found = false;
  prizearr.forEach((p) => {
    if (p == phone.value) {
      console.log("present!!");
      found = true;
    }
  });
  return found;
};
const cancelTournament = () => {
  tournamentData.value = {};
  phone.value = "";
  phoneOK.value = false;
  tournamentList.value = [];
  unsub();
};
const addPlayerNameToCard = () => {
  //   sorted_cards.value = [];
  sorted_cards.value = sorted_cards.value.map((card) => {
    for (const key in tournamentData.value.players) {
      if (tournamentData.value.players[key].phone == card.phone) {
        //card.player == tournamentData.value.players[key].player;
        return { ...card, player: tournamentData.value.players[key].player };
      }
    }
  });
  sortCardsByName();
};
const sortCardsByName = () => {
  sorted_cards.value.sort((a, b) => {
    let fa = a.player.toLowerCase(),
      fb = b.player.toLowerCase();
    //console.log(fa, fb);
    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
};
const updateStroke = async (hole, phone, score) => {
  const cardID = getCardID(phone);

  //console.log(cardID, hole, "Score=>", score);

  const cardRef = doc(db, "cards", cardID);
  const tempScore = getCardScore(cardID);
  const par = tournamentData.value.pars[hole - 1];
  tempScore[hole - 1] = parseInt(score);
  if (!tempScore[hole - 1]) {
    tempScore[hole - 1] = 0;
  }
  if (tempScore[hole - 1] - par >= 3) {
    tempScore[hole - 1] = par + 3;
  }

  await updateDoc(cardRef, {
    scores: tempScore,
  });
};
const getCardID = (phone) => {
  var id = "";
  sorted_cards.value.forEach((c) => {
    if (c.phone == phone) {
      id = c.id;
    }
  });
  return id;
};
const getCardScore = (cardid) => {
  var id = [];
  sorted_cards.value.forEach((c) => {
    if (c.id == cardid) {
      id = c.scores;
    }
  });
  return id;
};

const getValue = (hole, phone) => {
  var shots = 0;
  sorted_cards.value.forEach((c) => {
    if (c.phone == phone) {
      shots = c.scores[hole - 1];
    }
  });
  if (shots == "NaN") {
    shots = 0;
  }
  return shots;
};
const getStrokeClass = (hole, phone) => {
  const stroke = getValue(hole, phone);
  const par = tournamentData.value.pars[hole - 1];
  if (stroke == 0) {
    return "";
  }
  if (stroke == par) {
    return "par";
  }
  if (stroke > par) {
    return "bogey";
  }
  if (stroke < par) {
    return "birdie";
  }
};
const fetchSortedPlayers = () => {
  //console.log(tournamentData.value.players);

  for (const key in tournamentData.value.players) {
    sorted_players.value.push({
      id: key,
      ...tournamentData.value.players[key],
    });
  }
  sorted_players.value.sort((a, b) => {
    let fa = a.player.toLowerCase(),
      fb = b.player.toLowerCase();
    //console.log(fa, fb);
    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
};
const cardTotal = (phone) => {
  var score = 0;
  sorted_cards.value.forEach((c) => {
    if (c.phone == phone) {
      c.scores.forEach((i) => (score = score + i));
    }
  });
  return score;
};
const getBirdies = (phone) => {
  var birdies = 0;
  for (var hole = 1; hole < 19; hole++) {
    const stroke = getValue(hole, phone);
    const par = tournamentData.value.pars[hole - 1];

    if (stroke > 0 && stroke < par) {
      birdies++;
    }
  }
  return birdies;
};
const getPars = (phone) => {
  var pars = 0;
  for (var hole = 1; hole < 19; hole++) {
    const stroke = getValue(hole, phone);
    const par = tournamentData.value.pars[hole - 1];

    if (stroke > 0 && stroke == par) {
      pars++;
    }
  }
  return pars;
};
const getBogeys = (phone) => {
  var bogeys = 0;
  for (var hole = 1; hole < 19; hole++) {
    const stroke = getValue(hole, phone);
    const par = tournamentData.value.pars[hole - 1];

    if (stroke > 0 && stroke > par) {
      bogeys++;
    }
  }
  return bogeys;
};
const frontNine = (phone) => {
  var score = 0;
  sorted_cards.value.forEach((c) => {
    if (c.phone == phone) {
      c.scores.forEach((i, index) => {
        if (index <= 8) {
          score = score + i;
        }
      });
    }
  });
  return score;
};
const backNine = (phone) => {
  var score = 0;
  sorted_cards.value.forEach((c) => {
    if (c.phone == phone) {
      c.scores.forEach((i, index) => {
        if (index > 8) {
          score = score + i;
        }
      });
    }
  });
  return score;
};
</script>
<style scoped>
.noPadding {
  padding: 1px;
}
.birdie {
  background-color: #ffe6ea;
  border: 1px solid red;
}
.par {
  background-color: lightblue;
  border: 1px solid darkblue;
}
.bogey {
  background-color: lightgreen;
  border: 1px solid darkgreen;
}
</style>

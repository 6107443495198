<template>
  <div class="container-fluid">
    <div class="row d-flex justify-content-evenly mt-1">
      {{ tournamentData.name }}
    </div>
    <div class="row">
      <div class="col">
        <Transition>
          <div class="table-responsive">
            <table class="table table-sm border rounded no-gutters">
              <tr class="border rounded">
                <td></td>
                <td v-for="n in 18" :key="n">
                  <span class="holescore">{{ n }}</span>
                </td>
                <td class="holescore" @click="sortByTotal()">
                  T<i class="fa fa-sort"></i>
                </td>
                <td class="holescore" @click="sortByToPar()">
                  P <i class="fa fa-sort"></i>
                </td>
              </tr>

              <tr
                class="border rounded"
                v-for="card in tournamentCards"
                :key="card.id"
              >
                <!-- <td>{{ index }}</td> -->

                <td class="d-flex justify-content-start">
                  {{ getPlayerName(card.phone) }}
                </td>
                <td v-for="n in 18" :key="n" :class="getHiddenHole(n)">
                  <span
                    class="rounded holescore"
                    :class="getHoleColor(n, card.scores)"
                    v-if="card.scores[n - 1]"
                    >{{ card.scores[n - 1] }}</span
                  >
                </td>
                <td class="holescore">
                  {{ card.total }}
                </td>
                <td class="holescore">
                  {{ card.topar }}
                </td>
                <td class="holescore" v-if="tournamentData.hidden.length == 12">
                  {{ convertPeoriaToFloat(card.peoria) }}
                </td>
                <td
                  class="holescore"
                  v-if="
                    tournamentData.multipliers.first >= 3 &&
                    tournamentData.hidden.length == 6
                  "
                >
                  {{ convertPeoriaToFloat(card.peoria) }}
                </td>
              </tr>
            </table>
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useStore } from "../store/index";
import { db } from "../firebase/config";
import { onBeforeUnmount, onMounted, ref } from "vue";
import { collection, query, where, onSnapshot, doc } from "firebase/firestore";
const store = useStore();
const tournamentCards = ref([]);
const tournamentData = ref({});
const toggle = ref(true);
var unsubscribe;
var tournament_unsub;
onBeforeUnmount(() => {
  unsubscribe();
  tournament_unsub();
});
onMounted(() => {
  tournament_unsub = onSnapshot(
    doc(db, "tournaments", store.playingTournament),
    (dock) => {
      console.log("Current tournament data: ", dock.data());
      tournamentData.value = {
        id: dock.id,
        ...dock.data(),
      };
    }
  );

  const q = query(
    collection(db, "cards"),
    where("tournament", "==", store.playingTournament)
  );
  unsubscribe = onSnapshot(q, (querySnapshot) => {
    const cities = [];
    tournamentCards.value = [];
    querySnapshot.forEach((docy) => {
      cities.push({
        id: docy.id,
        ...docy.data(),
        total: tallyScore(docy.data().scores),
        topar: toparScore(docy.data().scores),
        peoria: toPeoriaScore(docy.data().scores),
      });
    });
    tournamentCards.value = cities;
    sortByPeoria();
  });
});
const toPeoriaScore = (arr) => {
  let hiddenHolesScore = 0;
  let totalScore = 0;
  let t_handicap = 0;
  let p_score = 0;
  if (tournamentData.value.hidden.length > 0) {
    arr.forEach((score, index) => {
      if (tournamentData.value.hidden.includes(index + 1)) {
        //console.log("hidden hole=>", index + 1, "score=>", score);
        hiddenHolesScore =
          hiddenHolesScore + considerOnlyTwoOverPar(score, index);
        //hiddenHolesScore = hiddenHolesScore + score;
      }
      //totalScore = totalScore + score;
      totalScore = totalScore + considerOnlyTwoOverPar(score, index);
    });

    t_handicap =
      (hiddenHolesScore * tournamentData.value.multipliers.first -
        coursePar()) *
      tournamentData.value.multipliers.second;
    console.log(t_handicap);
  } else {
    console.log("no doughnut");
  }
  //console.log(t_handicap.toFixed(2));
  p_score = totalScore - t_handicap.toFixed(2);
  p_score = p_score.toFixed(2);
  return p_score;
};
const coursePar = () => {
  let sum = 0;
  tournamentData.value.pars.forEach((h) => {
    sum = sum + h;
  });
  return sum;
};
const tallyScore = (arr) => {
  var score = 0;
  arr.forEach((s) => {
    score = score + s;
  });
  return score;
};
const toparScore = (arr) => {
  //console.log(arr, tournamentData.value.pars);
  var strokes = 0;
  var par = 0;
  arr.forEach((s, index) => {
    if (s) {
      par = par + tournamentData.value.pars[index];
      strokes = strokes + s;
      //console.log(par, strokes);
    }
    //console.log(s, index);
  });
  return strokes - par;
};
const getPlayerName = (phone) => {
  var name = null;
  // tournamentData.value.players.forEach((p) => {
  //   if (p.phone == phone.substr(phone.length - 10)) {
  //     name = p.player;
  //   }
  // });
  for (const key in tournamentData.value.players) {
    if (
      tournamentData.value.players[key].phone == phone.substr(phone.length - 10)
    ) {
      name = tournamentData.value.players[key].player;
    }
  }
  return name;
};
const getHoleColor = (hole, card) => {
  if (card[hole - 1] && card[hole - 1] < tournamentData.value.pars[hole - 1]) {
    return "birdie";
  }
  if (card[hole - 1] > tournamentData.value.pars[hole - 1]) {
    return "bogey";
  }
};
const sortByTotal = () => {
  if (toggle.value) {
    tournamentCards.value.sort((a, b) => a.total - b.total);
  } else {
    tournamentCards.value.sort((a, b) => b.total - a.total);
  }
  toggle.value = !toggle.value;
};
const sortByToPar = () => {
  if (toggle.value) {
    tournamentCards.value.sort((a, b) => a.topar - b.topar);
  } else {
    tournamentCards.value.sort((a, b) => b.topar - a.topar);
  }
  toggle.value = !toggle.value;
};
const sortByPeoria = () => {
  tournamentCards.value.sort((a, b) => a.peoria - b.peoria);
};
const getHiddenHole = (hole) => {
  if (tournamentData.value.hidden.includes(hole)) {
    return "bggreen";
  }
};
const convertPeoriaToFloat = (str) => {
  const fl = parseFloat(str).toFixed(1);
  return fl;
};
const considerOnlyTwoOverPar = (score, index) => {
  const hole = index + 1;
  let returnScore = 0;
  const par = tournamentData.value.pars[index];
  console.log(score, hole, par);
  if (score - par >= 2) {
    returnScore = par + 2;
  } else {
    returnScore = score;
  }
  return returnScore;
};
</script>
<style scoped>
.birdie {
  background-color: lightgreen;
  /* border: 1px solid darkgreen; */
  padding: 2px;
}
.bogey {
  background-color: #ffe6ea;
  /* border: 1px solid red; */
  padding: 2px;
}
.holescore {
  font-size: 10px;
  padding: 2px;
}
.bggreen {
  background-color: #defde0;
}
/* we will explain what these classes do next! */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

<template>
  <div class="container-fluid mt-2">
    <div class="row" v-if="!tournamentData.id">
      <div v-for="t in tournamentList" :key="t.id">
        <button class="btn btn-primary mt-1" @click="selectTournament(t)">
          {{ t.name }}
        </button>
      </div>
    </div>

    <table class="table table-bordered" v-if="tournamentData.id">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Team</th>
          <th scope="col">1</th>
          <th scope="col">2</th>
          <th scope="col">3</th>
          <th scope="col">4</th>
          <th scope="col">5</th>
          <th scope="col">6</th>
          <th scope="col">7</th>
          <th scope="col">8</th>
          <th scope="col">9</th>
          <th scope="col">10</th>
          <th scope="col">11</th>
          <th scope="col">12</th>
          <th scope="col">13</th>
          <th scope="col">14</th>
          <th scope="col">15</th>
          <th scope="col">16</th>
          <th scope="col">17</th>
          <th scope="col">18</th>
          <th scope="col">G</th>
          <th scope="col">H</th>
          <th scope="col">N</th>
        </tr>
      </thead>
      <tbody class="border">
        <tr v-for="(c, index) in sorted_cards" :key="c.id">
          <td class="align-middle listsno">{{ index + 1 }}</td>
          <td>
            {{ getTeamName(c.groupid) }}
            <ul class="list-group">
              <li
                class="list-group-item text-start smalltxt"
                v-for="p in getTeamPlayers(c.groupid)"
                :key="p"
              >
                <span class="nobrk">
                  {{ p.player }} ({{ getHandicap(p.phone) }})
                </span>
              </li>
            </ul>
          </td>
          <td
            v-for="h in 18"
            :key="h"
            class="align-middle"
            :class="getClass(c.scores[h - 1], h)"
          >
            <span v-if="c.scores[h - 1]">{{ c.scores[h - 1] }}</span>
          </td>
          <td class="align-middle">{{ tallyScore(c.scores) }}</td>
          <td class="align-middle">{{ teamHandicap(c.groupid) }}</td>
          <td class="align-middle">{{ c.nett }}</td>
        </tr>
      </tbody>
    </table>
    <div class="row" v-if="tournamentData.id">
      <div class="col">
        <i class="fa fa-home float-start" @click="toHome()"></i>
        <i
          class="fa fa-close float-end"
          v-if="tournamentData.id"
          @click="clearTournament()"
        ></i>
      </div>
    </div>
  </div>
</template>
<script setup>
import { db } from "../firebase/config";
import { onBeforeUnmount, onMounted, ref, computed } from "vue";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  getDocs,
} from "firebase/firestore";
const tournamentList = ref([]);
const tournamentData = ref({});
const allCards = ref([]);
import { useRouter } from "vue-router";
const router = useRouter();
const toHome = () => {
  router.push("/");
};
var unsub = () => {};
var unsub_cards = () => {};

const sorted_cards = computed(() => {
  var cards = [];
  allCards.value.forEach((c) => {
    var obj = {
      ...c,
      handicap: teamHandicap(c.groupid),
      total: tallyScore(c.scores),
      nett: tallyScore(c.scores) - teamHandicap(c.groupid),
    };
    if (obj.total != 0 && obj.handicap != 0 && obj.nett != 0) {
      cards.push(obj);
    }
  });
  const returnArray = cards.sort((a, b) => a.nett - b.nett);
  return returnArray;
});
var teamHandicap = (id) => {
  var individual_handicaps = [];
  var phones = [];
  var handicap = 0;
  for (const [key, value] of Object.entries(tournamentData.value.fourballs)) {
    //console.log(`Key: ${key}, Value: ${value}`);
    if (key == id) {
      value["players"].forEach((p) => {
        phones.push(p.phone);
      });
    } else {
      console.log("Name not found");
    }
  }
  phones.forEach((p) => {
    individual_handicaps.push(parseInt(getHandicap(p)));
  });
  individual_handicaps.forEach((h) => {
    handicap = handicap + parseInt(h);
  });
  if (individual_handicaps.length == 4) {
    handicap = Math.round(handicap * 0.2);
  }
  if (individual_handicaps.length == 3) {
    handicap = Math.round(handicap * 0.3);
  }
  return handicap;
};
const clearTournament = () => {
  tournamentData.value = {};
};
const selectTournament = async (tournament) => {
  unsub = onSnapshot(doc(db, "tournaments", tournament.id), (docy) => {
    tournamentData.value = { id: docy.id, ...docy.data() };
  });
  const q = query(
    collection(db, "cards"),
    where("tournament", "==", tournament.id)
  );
  unsub_cards = onSnapshot(q, (querySnapshot) => {
    const cities = [];
    querySnapshot.forEach((docy) => {
      cities.push({ id: docy.id, ...docy.data() });
    });
    allCards.value = cities;
    //console.log("Current cities in CA: ", cities.join(", "));
  });
  // tournamentData.value.resultcategory.forEach((r) => {
  //   result_category.value.push([]);
  // });
};
onMounted(async () => {
  console.log("fetching");
  const querySnapshot = await getDocs(collection(db, "tournaments"));
  querySnapshot.forEach((docy) => {
    if (docy.data().publish && docy.data().scramble) {
      //show only tournaments with published results
      tournamentList.value.push({ id: docy.id, ...docy.data() });
    }
  });
});
onBeforeUnmount(() => {
  unsub();
  unsub_cards();
});
const tallyScore = (arr) => {
  var score = 0;
  arr.forEach((s) => {
    score = score + s;
  });
  return score;
};
const getClass = (stroke, hole) => {
  var cl = "";
  const par = tournamentData.value.pars[hole - 1];
  if (stroke < par) {
    cl = "birdie";
  }
  if (stroke > par) {
    cl = "bogey";
  }
  if (stroke == par) {
    cl = "par";
  }
  if (stroke == 0) {
    cl = "";
  }
  return cl;
};
const getTeamName = (id) => {
  var name = "";
  if (tournamentData.value.fourballs[id].name) {
    name = tournamentData.value.fourballs[id].name;
  }
  return name;
};
const getTeamPlayers = (id) => {
  var players = [];
  if (tournamentData.value.fourballs[id].players) {
    players = tournamentData.value.fourballs[id].players;
  }
  return players;
};
const getHandicap = (ph) => {
  var handicap = "";
  for (const [key, value] of Object.entries(tournamentData.value.players)) {
    if (value.phone == ph) {
      handicap = value.handicap;
    }
  }
  return handicap;
};
</script>
<style scoped>
th {
  padding: 2x;
}
li {
  padding: 1px;
  font-size: 10px;
}
.smalltxt {
  padding: 1px;
  font-size: 10px;
}
.birdie {
  background-color: #ffe6ea;
  /* border: 1px solid red; */
}
.par {
  background-color: lightblue;
  /* border: 1px solid darkblue; */
}
.bogey {
  background-color: lightgreen;
  /* border: 1px solid darkgreen; */
}
.nobrk {
  white-space: nowrap;
}
.listsno {
  padding: 1px;
}
</style>

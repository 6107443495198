<template>
  Score Entered for {{ strokes.length }} holes. Total: {{ totalScore() }}
  <span v-if="strokes.length == 18" class="bg-warning">
    Press Enter to submit
  </span>
  <span v-if="err.length" class="bg-danger">
    {{ err }}
  </span>

  <input
    type="text"
    class="form-control"
    v-model.trim="strokes"
    @keyup.enter="addScore()"
  />
</template>
<script setup>
import { ref, watchEffect, defineProps } from "vue";
import { auth, db } from "../../firebase/config";
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
const strokes = ref("");
const err = ref("");
const props = defineProps(["cardid"]);
watchEffect(() => {
  if (strokes.value.length >= 19) {
    strokes.value = strokes.value.substring(0, 17);
  }
});
const addScore = async () => {
  if (strokes.value.length != 18) {
    err.value = "";
    err.value = "Please enter score for 18 holes";
  } else {
    const tempScore = [];
    [...strokes.value].forEach((c) => tempScore.push(parseInt(c)));
    const cardRef = doc(db, "cards", props.cardid);
    // await updateDoc(cardRef, {
    //   scores: [],
    // });
    await updateDoc(cardRef, {
      scores: tempScore,
    });
    strokes.value = "";
    err.value = "";
  }
};
const totalScore = () => {
  var total = 0;
  [...strokes.value].forEach((c) => {
    if (c >= "0" && c <= "9") {
      total = total + parseInt(c);
    } else {
      strokes.value = "";
    }
  });
  return total;
};
</script>

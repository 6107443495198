<template>
  <div class="container">
    <div class="card mx-auto mt-5" style="width: 18rem">
      <h5 class="card-title mt-3">GolfTango! Admin Login</h5>
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col">
            <input
              type="text"
              class="form-control"
              placeholder="Email"
              v-model.trim="email"
            />
            <input
              type="password"
              class="form-control mt-1"
              placeholder="Password"
              v-model.trim="password"
            />
            <button class="btn btn-primary mt-1" @click.prevent="login">
              Login
            </button>
          </div>
        </div>
      </div>

      <a href="/">Player Login</a>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useRouter } from "vue-router";
const email = ref("");
const password = ref("");
const router = useRouter();

const auth = getAuth();
const login = () => {
  signInWithEmailAndPassword(auth, email.value, password.value)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      console.log(user.uid);
      router.push("/adminhome");
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    });
};
</script>

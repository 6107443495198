<template>
  <div class="container">
    <div class="row" v-if="!tournamentData.id">
      <div v-for="t in tournamentList" :key="t.id">
        <button class="btn btn-primary mt-1" @click="selectTournament(t)">
          {{ t.name }}
        </button>
      </div>
      <div @click="toggleShowHandicap()">
        Show Handicap
        <span v-if="show_handicap"
          ><i class="fa fa-check" style="font-size: 24px"></i
        ></span>
        <span v-if="!show_handicap">
          <i class="fa fa-close" style="font-size: 24px"></i
        ></span>
      </div>
    </div>
    <div v-if="tournamentData.id">
      <div class="row">
        <div class="col">
          {{ tournamentData.name }} ({{ tournamentData.hidden.length }})
          <!-- {{ tournamentData.resultcategory }} -->
        </div>
      </div>
      <div class="row" v-for="row in result_category" :key="row">
        <!-- {{ getPlayerName(c.phone) }} ({{ getPlayerHandicap(c.phone) }}) -
          {{ c.peoria }} -->
        <!-- {{ tournamentData.resultcategory[i] }} -->
        <table class="table table-sm border rounded no-gutters">
          <tr
            v-for="(card, index) in sortArray(row)"
            :key="card.id"
            class="border rounded"
          >
            <td class="holescore">{{ index + 1 }}</td>
            <td class="text-start px-2 holescore">
              {{ getPlayerName(card.phone) }}
              <span v-if="show_handicap"
                >({{ getPlayerHandicap(card.phone) }})</span
              >
            </td>
            <!-- <td>{{ card.scores }}</td> -->
            <td v-for="n in 18" :key="n" :class="getHiddenHole(n)">
              <span
                class="rounded holescore"
                :class="getHoleColor(n, card.scores)"
                v-if="card.scores[n - 1]"
                >{{ card.scores[n - 1] }}</span
              >
            </td>
            <td class="holescore">{{ card.total }}</td>

            <td class="holescore">{{ card.peoria }}</td>
          </tr>
        </table>
      </div>

      <!-- <table class="table table-sm border rounded no-gutters">
        <tr
          v-for="(card, index) in sorted_cards"
          :key="card.id"
          class="border rounded"
        >
          <td class="holescore">{{ index + 1 }}</td>
          <td class="text-start px-2 holescore">
            {{ getPlayerName(card.phone) }} ({{
              getPlayerHandicap(card.phone)
            }})
          </td>

          <td v-for="n in 18" :key="n" :class="getHiddenHole(n)">
            <span
              class="rounded holescore"
              :class="getHoleColor(n, card.scores)"
              v-if="card.scores[n - 1]"
              >{{ card.scores[n - 1] }}</span
            >
          </td>
          <td class="holescore">{{ card.total }}</td>

          <td class="holescore">{{ card.peoria }}</td>
        </tr>
      </table> -->
    </div>
  </div>
</template>
<script setup>
import { useStore } from "../store/index";
import { db } from "../firebase/config";
import { onBeforeUnmount, onMounted, ref, watchEffect } from "vue";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  getDocs,
} from "firebase/firestore";
var unsub;
const tournamentData = ref({});
const tournamentList = ref([]);
const sorted_cards = ref([]);
const result_category = ref([]);
const allCards = ref([]);
const show_handicap = ref(true);
const toggleShowHandicap = () => {
  show_handicap.value = !show_handicap.value;
};
const sortArray = (arr) => {
  //var returnArray = [];
  const size = 100;
  const returnArray = arr.sort((a, b) => a.peoria - b.peoria);
  return returnArray.slice(0, size / result_category.value.length);
};
const PeoriaScore = (arr) => {
  let hiddenHolesScore = 0;
  let totalScore = 0;
  let t_handicap = 0;
  var p_score;
  if (tournamentData.value.hidden.length > 0) {
    arr.forEach((score, index) => {
      if (tournamentData.value.hidden.includes(index + 1)) {
        //console.log("hidden hole=>", index + 1, "score=>", score);
        hiddenHolesScore =
          hiddenHolesScore + considerOnlyTwoOverPar(score, index);
        //hiddenHolesScore = hiddenHolesScore + score;
      }
      //totalScore = totalScore + score;
      totalScore = totalScore + considerOnlyTwoOverPar(score, index);
    });
    t_handicap =
      (hiddenHolesScore * tournamentData.value.multipliers.first.toFixed(2) -
        coursePar()) *
      tournamentData.value.multipliers.second.toFixed(2);
    //console.log("latest tournament handicap=>", t_handicap);
  } else {
    console.log("no doughnut");
  }
  //console.log(t_handicap.toFixed(2));
  p_score = totalScore - t_handicap.toFixed(2);
  p_score = p_score.toFixed(2);
  return p_score;
};
const coursePar = () => {
  let sum = 0;
  tournamentData.value.pars.forEach((h) => {
    sum = sum + h;
  });
  return sum;
};
const considerOnlyTwoOverPar = (score, index) => {
  const hole = index + 1;
  let returnScore = 0;
  const par = tournamentData.value.pars[index];
  //console.log(score, hole, par);
  if (score - par >= 2) {
    returnScore = par + 2;
  } else {
    returnScore = score;
  }
  return returnScore;
};
const sortCards = (hidden) => {
  // console.log(allCards.value.length, hidden);
  sorted_cards.value = [];
  result_category.value = [];
  if (tournamentData.value.id) {
    tournamentData.value.resultcategory.forEach((r) => {
      result_category.value.push([]);
    });
  }

  allCards.value.forEach((c) => {
    // console.log("fetchHandicap=>", getPlayerHandicap(c.phone));
    // console.log(
    //   "PUSH INDEX=>",
    //   searchWhichIndexInResultCategoryArray(getPlayerHandicap(c.phone))
    // );
    result_category.value[
      searchWhichIndexInResultCategoryArray(getPlayerHandicap(c.phone))
    ].push({
      ...c,
      peoria: PeoriaScore(c.scores),
    });
    sorted_cards.value.push({
      ...c,
      peoria: PeoriaScore(c.scores),
    });
  });
  sorted_cards.value.sort((a, b) => a.peoria - b.peoria);
};
const searchWhichIndexInResultCategoryArray = (handicap) => {
  var start = "";
  var end = "";
  var pushIndex = "";
  tournamentData.value.resultcategory.forEach((value, index) => {
    const limits = value.split(",");
    start = limits[0];
    end = limits[1];
    if (handicap <= parseInt(end) && handicap >= parseInt(start)) {
      pushIndex = index;
      console.log(index, handicap, limits, value, start, end);
    }
  });
  return pushIndex;
};

onBeforeUnmount(() => {
  unsub();
});
onMounted(async () => {
  console.log("fetching");
  const querySnapshot = await getDocs(collection(db, "tournaments"));
  querySnapshot.forEach((docy) => {
    if (docy.data().publish) {
      //show only tournaments with published results
      tournamentList.value.push({ id: docy.id, ...docy.data() });
    }
  });
});
const tallyScore = (arr) => {
  var score = 0;
  arr.forEach((s) => {
    score = score + s;
  });
  return score;
};
const selectTournament = async (tournament) => {
  unsub = onSnapshot(doc(db, "tournaments", tournament.id), (docy) => {
    tournamentData.value = { id: docy.id, ...docy.data() };
  });
  const q = query(
    collection(db, "cards"),
    where("tournament", "==", tournament.id)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((dock) => {
    allCards.value.push({
      id: dock.id,
      ...dock.data(),
      total: tallyScore(dock.data().scores),
    });
  });
  // tournamentData.value.resultcategory.forEach((r) => {
  //   result_category.value.push([]);
  // });
};
watchEffect(() => {
  sortCards(tournamentData.value.hidden);
});

const getPlayerName = (ph) => {
  var name = "";
  for (const key in tournamentData.value.players) {
    if (tournamentData.value.players[key].phone == ph) {
      name = tournamentData.value.players[key].player;
    }
  }
  return name;
};
const getPlayerHandicap = (ph) => {
  var handicap = "";
  for (const key in tournamentData.value.players) {
    if (tournamentData.value.players[key].phone == ph) {
      handicap = tournamentData.value.players[key].handicap;
    }
  }
  return handicap;
};
const getHiddenHole = (hole) => {
  if (tournamentData.value.hidden.includes(hole)) {
    return "bggreen";
  }
};
const getHoleColor = (hole, card) => {
  if (card[hole - 1] && card[hole - 1] < tournamentData.value.pars[hole - 1]) {
    return "birdie";
  }
  if (card[hole - 1] > tournamentData.value.pars[hole - 1]) {
    return "bogey";
  }
};
</script>
<style scoped>
.birdie {
  background-color: #ffe6ea;
  /* border: 1px solid darkgreen; */
  padding: 2px;
}
.bogey {
  background-color: lightgreen;
  /* border: 1px solid red; */
  padding: 2px;
}
.holescore {
  font-size: 16px;
  padding: 2px;
}
.bggreen {
  /* background-color: #defde0; */
  background-color: #add8e6;
}
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.container-fluid {
  background-color: #faf9f6;
}
</style>

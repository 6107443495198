<template>
  <ul class="list-group">
    <li class="list-group-item medtxt" v-for="g in sorted_fourballs" :key="g">
      Group: {{ g.sno }} - {{ g.id }}
      <button
        class="btn btn-sm btn-primary float-end"
        v-if="!checkIfCardExists(g.id)"
        @click="openCard(g.id, g.sno)"
      >
        Open
      </button>
    </li>
  </ul>
  <ul class="list-group">
    <li class="list-group-item medtxt" v-for="c in cards" :key="c.id">
      Delete card for:
      <span @click="selectToDelete(c.groupid)" class="border border-primary">{{
        c.groupid
      }}</span>
      <i
        class="fa fa-trash-o float-end"
        v-if="toDelete == c.groupid"
        @click="deleteCard(c.id)"
      ></i>
    </li>
  </ul>
</template>
<script setup>
import { useStore } from "../../store/index";
import { ref, onMounted, onBeforeUnmount, watchEffect } from "vue";
import { db } from "../../firebase/config";
import {
  collection,
  query,
  where,
  doc,
  onSnapshot,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
const store = useStore();
const tournament = ref({});
const cards = ref([]);
const toDelete = ref("");
const sorted_fourballs = ref([]);
var unsub_tournament = () => {};
var unsub_cards = () => {};
const selectToDelete = (id) => {
  if (toDelete.value != id) {
    toDelete.value = id;
  } else {
    toDelete.value = "";
  }
};
const openCard = async (groupid, gsno) => {
  const newCard = {
    tournament: store.selectedTournament,
    scores: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    groupid: groupid,
    sno: gsno,
    submitted: false,
  };
  await addDoc(collection(db, "cards"), newCard);
};
const deleteCard = async (id) => {
  await deleteDoc(doc(db, "cards", id));
};
const checkIfCardExists = (groupid) => {
  var exists = false;
  cards.value.forEach((c) => {
    if (c.groupid == groupid) {
      exists = true;
    }
  });
  return exists;
};
onMounted(() => {
  unsub_tournament = onSnapshot(
    doc(db, "tournaments", store.selectedTournament),
    (docy) => {
      tournament.value = docy.data();
    }
  );
  const q = query(
    collection(db, "cards"),
    where("tournament", "==", store.selectedTournament)
  );
  unsub_cards = onSnapshot(q, (querySnapshot) => {
    const cities = [];
    querySnapshot.forEach((docy) => {
      cities.push({ id: docy.id, ...docy.data() });
    });
    cards.value = cities;
    //console.log("Current cities in CA: ", cities.join(", "));
  });
});
onBeforeUnmount(() => {
  unsub_tournament();
  unsub_cards();
});
watchEffect(() => {
  sorted_fourballs.value = [];
  for (const key in tournament.value.fourballs) {
    sorted_fourballs.value.push({
      id: key,
      ...tournament.value.fourballs[key],
    });
  }
  sorted_fourballs.value.sort((a, b) => {
    let fa = a.sno;
    let fb = b.sno;
    //console.log(fa, fb);
    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
});
</script>
<style scoped>
.medtxt {
  padding: 1px;
  font-size: 14px;
}
</style>

import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Admin from "../views/Admin.vue";
import LeaderBoard from "../components/LeaderBoard.vue";
import PlayerHome from "../components/PlayerHomePage.vue";
import AdminHome from "../components/admin/AdminHome.vue";
import Tournament from "../components/admin/Tournament.vue";
import Players from "../components/admin/Players.vue";
import ScoringSheet from "../components/admin/ScoringSheet.vue";
import ScrambleScoring from "../components/scramble/ScrambleScoring.vue";
import ResultBoard from "../components/ResultBoard.vue";
import FourBalls from "../components/admin/FourBalls.vue";
import SpotPrize from "../components/admin/SpotPrize.vue";
import Register from "../components/admin/RegisterTag.vue";
import ScoreCards from "../components/admin/ScoreCards.vue";
import LiveResults from "../components/LiveResults.vue";
import ScrambleResults from "../components/ScrambleResults.vue";
import { auth } from "../firebase/config";
const requireAuth = (to, from, next) => {
  let user = auth.currentUser;
  console.log("checking requireAuth => ", user);
  if (!user) {
    next({ name: "Admin" });
  } else {
    next();
  }
};
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
  },
  {
    path: "/adminhome",
    name: "AdminHome",
    component: AdminHome,
    beforeEnter: requireAuth,
  },
  {
    path: "/tournament",
    name: "Tournament",
    component: Tournament,
    beforeEnter: requireAuth,
  },
  {
    path: "/players",
    name: "Players",
    component: Players,
    beforeEnter: requireAuth,
  },
  {
    path: "/home",
    name: "PlayerHome",
    component: PlayerHome,
    beforeEnter: requireAuth,
  },
  {
    path: "/scores",
    name: "Leaderboard",
    component: LeaderBoard,
    beforeEnter: requireAuth,
  },
  {
    path: "/results",
    name: "Results",
    //component: ResultBoard,
    component: LiveResults,
    // beforeEnter: requireAuth,
  },
  {
    path: "/scramble",
    name: "Scramble",
    //component: ResultBoard,
    component: ScrambleResults,
    // beforeEnter: requireAuth,
  },
  {
    path: "/scramblescoring",
    name: "ScrambleScoring",
    //component: ResultBoard,
    component: ScrambleScoring,
    // beforeEnter: requireAuth,
  },
  {
    path: "/scoring",
    name: "Scoring",
    component: ScoringSheet,
    beforeEnter: requireAuth,
  },
  {
    path: "/fourballs",
    name: "Fourballs",
    component: FourBalls,
    beforeEnter: requireAuth,
  },
  {
    path: "/spotprize",
    name: "SpotPrize",
    component: SpotPrize,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  // {
  //   path: "/result",
  //   name: "LiveResults",
  //   component: LiveResults,
  // },
  {
    path: "/scorecards",
    name: "Scorecards",
    component: ScoreCards,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

<template>
  <span v-if="!showInput" @click="toggleShow()">{{ handi }}</span>
  <input
    type="text"
    class="form-control medtxt"
    v-if="showInput"
    v-model.number="handi"
    @keyup.enter="updateHandicap()"
  />
</template>
<script setup>
import { defineProps, ref, watchEffect } from "vue";
import { useStore } from "../../store/index";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/config";
const props = defineProps(["handicap", "id", "tournament"]);
const showInput = ref(false);
const handi = ref("");
const store = useStore();
watchEffect(() => {
  //handicap.value = props.handicap;
  for (const [key, value] of Object.entries(props.tournament.players)) {
    //console.log(`Key: ${key}, Value: ${value}`);
    if (key == props.id) {
      handi.value = value["handicap"];
    } else {
      console.log("Name not found");
    }
  }
});
const toggleShow = () => {
  showInput.value = !showInput.value;
};
const updateHandicap = async () => {
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  await updateDoc(washingtonRef, {
    ["players." + props.id + ".handicap"]: handi.value,
    //fourballs: arrayUnion(fourballObj),
  });
  showInput.value = false;
};
</script>
<style scoped>
.medtxt {
  padding: 1px;
  font-size: 14px;
}
</style>

<template>
  <span class="float-start" v-if="!showInput" @click="toggleInput()">{{
    name
  }}</span>
  <input
    type="text"
    class="form-control medtxt"
    v-model.trim="name"
    v-if="showInput"
    @keyup.enter="enterName()"
  />
  <i class="fa fa-plus float-end" @click="toggleInput()" v-if="!name"></i>
</template>
<script setup>
import { defineProps, ref, watchEffect } from "vue";
import { useStore } from "../../store/index";
import { db } from "../../firebase/config";
import { doc, updateDoc } from "firebase/firestore";
const store = useStore();
const name = ref("");
const toggleInput = () => {
  showInput.value = !showInput.value;
};
const showInput = ref(false);
var props = defineProps(["id", "fourballs"]);
watchEffect(() => {
  for (const [key, value] of Object.entries(props.fourballs)) {
    //console.log(`Key: ${key}, Value: ${value}`);
    if (key == props.id && value["name"]) {
      name.value = value["name"];
    } else {
      console.log("Name not found");
    }
  }
});
const enterName = async () => {
  //console.log("Name:", name.value);
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);
  await updateDoc(washingtonRef, {
    ["fourballs." + props.id + ".name"]: name.value,
    //fourballs: arrayUnion(fourballObj),
  });
  showInput.value = false;
};
</script>
<style scoped>
.medtxt {
  padding: 1px;
  font-size: 14px;
}
</style>

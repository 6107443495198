<template>
  <ul class="list-group">
    <li class="list-group-item" v-for="c in cards" :key="c.id">
      <!-- {{ c.id }} - {{ c.scores }} - {{ props.pars }} -->
      <div class="card mx-auto" style="width: 40rem">
        <div class="card-body">
          <!-- <h5 class="card-title">{{ c.groupid }}</h5> -->
          <div class="row">
            <div class="col align-vertical">
              <h5 class="card-title">{{ getTeamName(c.groupid) }}</h5>
            </div>
            <div class="col">
              <ul class="list-group">
                <li
                  class="list-group-item medtxt"
                  v-for="p in getPlayers(c.groupid)"
                  :key="p"
                >
                  {{ p.player }} ({{ getHandicap(p.phone) }})
                </li>
              </ul>
            </div>
          </div>
          <table class="table table-sm table-striped table-bordered">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Score</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="h in 18" :key="h">
                <th
                  scope="row"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  @click="scoringData(c.id, h, c.scores)"
                >
                  {{ h }}
                </th>
                <td :class="getClass(c.scores[h - 1], h)">
                  <span v-if="c.scores[h - 1]">{{ c.scores[h - 1] }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </li>
  </ul>

  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Enter score for : {{ cardid }}, hole: {{ scorehole }}, Par:
            {{ props.pars[scorehole - 1] }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body d-flex justify-content-around" v-if="cardid">
          <!-- {{ props.pars }} -->
          <i class="fa fa-caret-left float-start d-flex align-items-center"></i>
          <button
            :class="['btn', 'btn-sm', getClass(stroke, scorehole)]"
            v-for="stroke in strokerange(props.pars[scorehole - 1])"
            :key="stroke"
            @click="setScore(stroke, scorehole)"
            data-bs-dismiss="modal"
          >
            {{ stroke }}
          </button>
          <i class="fa fa-caret-right float-end d-flex align-items-center"></i>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="setScore(0, scorehole)"
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, watchEffect, ref } from "vue";
import { db } from "../../firebase/config";
import { doc, updateDoc } from "firebase/firestore";
const props = defineProps(["cards", "phone", "scorers", "pars", "tournament"]);
const cards = ref([]);
const cardid = ref("");
const tempscore = ref([]);
const scorehole = ref("");

const strokerange = (par) => {
  var range = [par - 2, par - 1, par, par + 1, par + 2];
  return range;
};
const setScore = async (stroke, hole) => {
  console.log(cardid.value, stroke, hole);
  tempscore.value[hole - 1] = stroke;
  console.log(tempscore.value);
  const washingtonRef = doc(db, "cards", cardid.value);
  await updateDoc(washingtonRef, {
    scores: tempscore.value,
  });
  scoringData(null, null, null); //clearing selection
};
const getClass = (stroke, hole) => {
  var cl = "";
  const par = props.pars[hole - 1];
  if (stroke < par) {
    cl = "birdie";
  }
  if (stroke > par) {
    cl = "bogey";
  }
  if (stroke == par) {
    cl = "par";
  }
  if (stroke == 0) {
    cl = "";
  }
  return cl;
};
var scoringData = (id, hole, scores) => {
  cardid.value = id;
  scorehole.value = hole;
  tempscore.value = scores;
};
watchEffect(() => {
  cards.value = [];
  props.cards.forEach((c) => {
    props.scorers.forEach((s) => {
      if (s.phone == props.phone && c.groupid == s.fourball) {
        cards.value.push(c);
      }
    });
  });
});
const getTeamName = (id) => {
  var name = "";
  for (const [key, value] of Object.entries(props.tournament.fourballs)) {
    //console.log(`Key: ${key}, Value: ${value}`);
    if (key == id && value["name"]) {
      name = value["name"];
    } else {
      console.log("Name not found");
    }
  }
  return name;
};
const getPlayers = (id) => {
  var players = [];
  for (const [key, value] of Object.entries(props.tournament.fourballs)) {
    //console.log(`Key: ${key}, Value: ${value}`);
    if (key == id) {
      players = value["players"];
    } else {
      console.log("Name not found");
    }
  }
  return players;
};
const getHandicap = (ph) => {
  var handicap = "";
  for (const [key, value] of Object.entries(props.tournament.players)) {
    if (value.phone == ph) {
      handicap = value.handicap;
    }
  }
  return handicap;
};
</script>
<style scoped>
.birdie {
  background-color: #ffe6ea;
  border: 1px solid red;
}
.par {
  background-color: lightblue;
  border: 1px solid darkblue;
}
.bogey {
  background-color: lightgreen;
  border: 1px solid darkgreen;
}
.medtxt {
  padding: 1px;
  font-size: 14px;
}
</style>

<template>
  <div class="container">
    <div v-if="!phoneOK">
      <div class="card mx-auto mt-5" style="width: 18rem">
        <div class="card-body">
          <h5 class="card-title">Register & Tag</h5>
          <div class="row">
            <input
              type="text"
              class="form-control"
              placeholder="Phone"
              v-model.trim="phone"
            />
          </div>
          <div class="row mt-1">
            <button class="btn btn-primary" @click="submitPhone()">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="phoneOK && !tournamentData.id" class="mt-1">
      <div v-for="t in tournamentList" :key="t.id">
        <button class="btn btn-primary mr-1" @click="selectTournament(t)">
          {{ t.name }}
        </button>
      </div>
    </div>
    <div v-if="tournamentData.id">
      <i class="fa fa-close float-end" @click="cancelTournament()"></i>
      <table class="table table-sm mt-2">
        <tr>
          <td>Name ({{ sorted_players.length }})</td>
          <td></td>
          <td></td>
          <td>{{ tagCount() }}/{{ sorted_players.length }}</td>
          <td>{{ regdCount() }}/{{ sorted_players.length }}</td>
        </tr>

        <tr v-for="t in sorted_players" :key="t" class="border rounded">
          <td>
            <i
              class="fa fa-close float-start"
              v-if="t.phone == selectedPlayer"
              @click="removePlayer(t.id)"
            ></i>
            <div @click="selectPlayer(t)">{{ t.player }}</div>
          </td>
          <td>{{ t.phone }}</td>
          <td>{{ t.handicap }}</td>
          <td>
            <i
              class="bi bi-tag"
              v-if="!t.bagtag"
              @click="toggleTag(t.id, t.bagtag)"
            ></i>
            <i
              class="bi bi-check"
              v-if="t.bagtag"
              @click="toggleTag(t.id, t.bagtag)"
            ></i>
          </td>
          <td>
            <i
              class="bi bi-clipboard"
              v-if="!t.registration"
              @click="toggleRegistration(t.id, t.registration)"
            ></i>
            <i
              class="bi bi-check"
              v-if="t.registration"
              @click="toggleRegistration(t.id, t.registration)"
            ></i>
          </td>

          <td v-if="shotgun">
            <div class="dropdown">
              <button
                class="btn btn-secondary dropdown-toggle btn-sm"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span v-if="t.startHole">{{ t.startHole }}</span>
                <span v-else>Hole</span>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li
                  class="dropdown-item border rounded text-center"
                  v-for="n in 18"
                  :key="n"
                  @click="setStartHole(t.id, n)"
                >
                  {{ n }}
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script setup>
import { ref, watchEffect, onBeforeUnmount } from "vue";
import {
  collection,
  getDocs,
  query,
  where,
  onSnapshot,
  arrayUnion,
  arrayRemove,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
const phone = ref("");
const phoneOK = ref(false);
const tournamentList = ref([]);
const tournamentData = ref({});
const sorted_players = ref([]);
var unsub;
const sortedPlayers = () => {
  sorted_players.value = [];
  //console.log("sorting these=>", players.value);
  for (const key in tournamentData.value.players) {
    sorted_players.value.push({
      id: key,
      ...tournamentData.value.players[key],
    });
  }
  //console.log("before sort=>", tempArr);
  sorted_players.value.sort((a, b) => {
    let fa = a.player.toLowerCase(),
      fb = b.player.toLowerCase();
    console.log(fa, fb);
    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
  //console.log("sorted =>", tempArr);
  // sorted_players.value = tempArr;
  //return tempArr;
};
onBeforeUnmount(() => {
  console.log("unmounting...");
  unsub();
});
const submitPhone = async () => {
  //console.log(phone.value);
  const querySnapshot = await getDocs(collection(db, "tournaments"));
  querySnapshot.forEach((docy) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(docy.id, " => ", docy.data());
    if (checkIfPhonePresent(docy.data().registration)) {
      tournamentList.value.push({ id: docy.id, ...docy.data() });
      phoneOK.value = true;
    }
  });
};
const selectTournament = (tournament) => {
  unsub = onSnapshot(doc(db, "tournaments", tournament.id), (docy) => {
    tournamentData.value = { id: docy.id, ...docy.data() };
  });
};
watchEffect(() => {
  sortedPlayers();
});
const checkIfPhonePresent = (prizearr) => {
  var found = false;
  prizearr.forEach((p) => {
    if (p == phone.value) {
      console.log("present!!");
      found = true;
    }
  });
  return found;
};
const cancelTournament = () => {
  tournamentData.value = {};
  phone.value = "";
  phoneOK.value = false;
  tournamentList.value = [];
  unsub();
};
const regdCount = () => {
  var count = 0;
  sorted_players.value.forEach((p) => {
    if (p.registration) {
      count++;
    }
  });
  return count;
};
const tagCount = () => {
  var count = 0;
  sorted_players.value.forEach((p) => {
    if (p.bagtag) {
      count++;
    }
  });
  return count;
};
const toggleRegistration = async (id, value) => {
  //console.log(id);
  const washingtonRef = doc(db, "tournaments", tournamentData.value.id);
  await updateDoc(washingtonRef, {
    ["players." + id + ".registration"]: !value,
  });
};
const toggleTag = async (id, value) => {
  //console.log(id);
  const washingtonRef = doc(db, "tournaments", tournamentData.value.id);
  await updateDoc(washingtonRef, {
    ["players." + id + ".bagtag"]: !value,
  });
};
</script>

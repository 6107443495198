<template>
  <nav
    class="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-around"
  >
    <button class="btn btn-sm btn-danger" @click="toScore()">Scores</button>
    <button class="btn btn-sm btn-primary" @click="toCard()">Card</button>
    <button class="btn btn-sm btn-secondary" @click="logout">Logout</button>
  </nav>
</template>
<script setup>
import { signOut } from "firebase/auth";
import { auth } from "../firebase/config";
import { useRouter } from "vue-router";
const router = useRouter();
const logout = () => {
  signOut(auth)
    .then(() => {
      // Sign-out successful.
      console.log("player signout succesful");
      router.push("/");
    })
    .catch((error) => {
      // An error happened.
      console.log(error);
    });
};
const toScore = () => {
  router.push("/scores");
};
const toCard = () => {
  router.push("/home");
};
</script>

<template>
  <div class="container">
    <Navbar></Navbar>
    <div class="row">
      <div class="col">
        <div class="row" v-if="!store.selectedTournament">
          <ul class="list-group">
            <li
              v-for="t in tournaments"
              class="list-group-item"
              :key="t.id"
              @click="selectThisTournament(t.id)"
            >
              {{ t.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row" v-if="store.selectedTournament">
      <div class="col">
        <input
          type="text"
          class="form-control"
          placeholder="Search"
          @key.up="searchPlayer()"
          @click="clearSearchStr()"
          v-model.trim="search_str"
        />
        <!-- <SearchPlayer></SearchPlayer> -->
      </div>
      <div class="col">
        <div
          class="btn btn-sm btn-primary float-start"
          v-if="!tournamentData.publish"
          @click="resultPublishToggle()"
        >
          Publish
        </div>
        <div
          class="btn btn-sm btn-danger float-start"
          @click="resultPublishToggle()"
          v-if="tournamentData.publish"
        >
          Live!
        </div>
        <div
          class="btn btn-sm btn-primary ms-1"
          @click="fillCardsWithDummyData()"
        >
          DummyData
        </div>
        <!-- <div class="btn btn-sm btn-danger ms-1" @click="openAllCards()">
          OpenAllCards
        </div> -->
        <div class="btn btn-sm btn-secondary ms-1" @click="clearAllCards()">
          ClearAll
        </div>
        {{ tournamentData.name }}
      </div>
      <div class="col-1 float-end">
        <i class="fa fa-close" @click="store.clearTournament()"></i>
      </div>
    </div>
    <div class="row" v-if="store.selectedTournament">
      <table class="table table-hover table-sm">
        <tbody>
          <tr
            v-for="player in tournamentData.players"
            :key="player"
            class="align-middle"
            :class="showPlayer(player)"
          >
            <!-- <th>{{ getPlayerName(player.phone) }}</th> -->

            <th @click="selectDelete(player.phone)" class="text-start">
              {{ player.player }}
            </th>

            <td>
              <button
                class="btn btn-sm btn-success"
                v-if="!isCardPresent(player.phone)"
                @click="openCard(player.phone)"
              >
                Open
              </button>
              <div
                v-if="
                  player.phone == cardToDelete && isCardPresent(player.phone)
                "
              >
                <EnteringScores
                  :cardid="getCardID(player.phone)"
                ></EnteringScores>
              </div>
            </td>

            <td v-for="hole in 18" :key="hole" class="noPadding">
              <div
                class="noPadding rounded"
                :class="getStrokeClass(hole, player.phone)"
              >
                {{ getValue(hole, player.phone) }}
              </div>
            </td>
            <td>
              <h3>{{ cardTotal(player.phone) }}</h3>
            </td>
            <td class="noPadding">
              <i
                class="fa fa-close"
                style="font-size: 18px; color: red"
                v-if="
                  player.phone == cardToDelete && isCardPresent(player.phone)
                "
                @click="deleteCard(player.phone)"
              ></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref, watchEffect } from "vue";
import { useStore } from "../../store/index";
import { auth, db } from "../../firebase/config";
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import Navbar from "./AdminNavbar.vue";
import EnteringScores from "./EnteringScores.vue";
import SearchPlayer from "./SearchPlayer.vue";

import TournamentTemplate from "./TournamentTemplate.vue";
const selectedTournament = ref(null);
const store = useStore();
var unsubscribe;
var cardsUnsub;
const tournaments = ref([]);
const cards = ref([]);
const tournamentData = ref({});
const cardToDelete = ref("");
const scoreString = ref("");
const search_str = ref("");
const clearSearchStr = () => {
  if (search_str.value.length >= 2) {
    search_str.value = "";
  }
};
function isSubstring(needle, haystack) {
  return haystack.indexOf(needle) !== -1;
}
const showPlayer = (player) => {
  var returnClass = "";
  if (search_str.value.length >= 3) {
    returnClass = "hidden-row";
    if (
      isSubstring(search_str.value.toLowerCase(), player.player.toLowerCase())
    ) {
      returnClass = "";
    }
  }
  return returnClass;
};

// const getPlayerName = (phone) => {
//   var name = null;
//   typeof phone;
//   // tournamentData.value.players.forEach((p) => {
//   //   if (p.phone == phone.substr(phone.length - 10)) {
//   //     name = p.player;
//   //   }
//   // });
//   for (const key in tournamentData.value.players) {
//     if (
//       tournamentData.value.players[key].phone == phone.substr(phone.length - 10)
//     ) {
//       name = tournamentData.value.players[key].player;
//     }
//   }
//   return name;
// };

onBeforeUnmount(() => {
  unsubscribe();
  cardsUnsub();
});
onMounted(() => {
  //console.log("mounted", auth.currentUser.uid);
  const q = query(
    collection(db, "tournaments"),
    where("admin", "==", auth.currentUser.uid)
  );
  unsubscribe = onSnapshot(q, (querySnapshot) => {
    const cities = [];
    querySnapshot.forEach((docy) => {
      cities.push({ id: docy.id, ...docy.data() });
    });
    tournaments.value = [];
    cities.forEach((c) => {
      tournaments.value.push(c);
    });
    //console.log("Current cities in CA: ", cities.join(", "));
  });
});
const selectThisTournament = (id) => {
  store.setTournament(id);
};
watchEffect(() => {
  //console.log(store.selectedTournament);
  tournaments.value.forEach((t) => {
    if (t.id == store.selectedTournament) {
      tournamentData.value = { ...t };
    }
  });
  const q = query(
    collection(db, "cards"),
    where("tournament", "==", store.selectedTournament)
  );
  cardsUnsub = onSnapshot(q, (querySnapshot) => {
    const cities = [];
    querySnapshot.forEach((docy) => {
      cities.push({ id: docy.id, ...docy.data() });
    });
    //console.log("Current cities in CA: ", cities.join(", "));
    cards.value = cities;
  });
});
const isCardPresent = (phone) => {
  var returnValue = false;
  cards.value.forEach((card) => {
    if (card.phone == phone) {
      returnValue = true;
    }
  });
  return returnValue;
};
const openCard = async (phone) => {
  const newCard = {
    tournament: store.selectedTournament,
    scores: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    phone: phone.toString(),
    submitted: false,
  };
  const docRef = await addDoc(collection(db, "cards"), newCard);
};
const updateStroke = async (hole, phone, name, score) => {
  const cardID = getCardID(phone);
  //console.log(cardID, hole, phone, name, "Score=>", score);

  const cardRef = doc(db, "cards", cardID);
  const tempScore = getCardScore(phone);
  const par = tournamentData.value.pars[hole - 1];
  tempScore[hole - 1] = parseInt(score);
  if (!tempScore[hole - 1]) {
    tempScore[hole - 1] = 0;
  }
  if (tempScore[hole - 1] - par >= 3) {
    tempScore[hole - 1] = par + 3;
  }

  await updateDoc(cardRef, {
    scores: tempScore,
  });
};

function containsOnlyDigits(str) {
  console.log(str);
  return /^\d+$/.test(str);
}
const getCardID = (phone) => {
  var id = "";
  cards.value.forEach((c) => {
    if (c.phone == phone) {
      id = c.id;
    }
  });
  return id;
};
const getCardScore = (phone) => {
  var id = [];
  cards.value.forEach((c) => {
    if (c.phone == phone) {
      id = c.scores;
    }
  });
  return id;
};
const getModalStrokeClass = () => {
  return true;
};
const getValue = (hole, phone) => {
  var shots = 0;
  cards.value.forEach((c) => {
    if (c.phone == phone) {
      shots = c.scores[hole - 1];
    }
  });
  if (shots == "NaN") {
    shots = 0;
  }
  return shots;
};
const getStrokeClass = (hole, phone) => {
  const stroke = getValue(hole, phone);
  const par = tournamentData.value.pars[hole - 1];
  if (stroke == 0) {
    return "";
  }
  if (stroke == par) {
    return "par";
  }
  if (stroke > par) {
    return "bogey";
  }
  if (stroke < par) {
    return "birdie";
  }
};
const getHolePar = (hole, phone) => {
  const par = tournamentData.value.pars[hole - 1];
};
const cardTotal = (phone) => {
  var score = 0;
  cards.value.forEach((c) => {
    if (c.phone == phone) {
      c.scores.forEach((i) => (score = score + i));
    }
  });
  return score;
};
const resultPublishToggle = async () => {
  console.log(store.selectedTournament);
  const washingtonRef = doc(db, "tournaments", store.selectedTournament);

  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    publish: !tournamentData.value.publish,
  });
};
const selectDelete = (phone) => {
  if (cardToDelete.value != phone) {
    cardToDelete.value = phone;
  } else {
    cardToDelete.value = "";
  }
};
const deleteCard = async (phone) => {
  const cardID = getCardID(phone);
  const cardRef = doc(db, "cards", cardID);
  await deleteDoc(cardRef);
};
const addScore = async (player) => {
  const tempScore = [];
  //console.log(scoreString.value, player);
  [...scoreString.value].forEach((c) => tempScore.push(parseInt(c)));
  const cardID = getCardID(player.phone);
  //console.log(cardID, hole, phone, name, "Score=>", score);
  const cardRef = doc(db, "cards", cardID);
  await updateDoc(cardRef, {
    scores: [],
  });
  await updateDoc(cardRef, {
    scores: tempScore,
  });
  scoreString.value = "";
  selectDelete(player.phone);
};
function generateRandomStrokes() {
  const min = 2;
  const max = 8;
  const arrayLength = 18;
  const randomDigits = [];

  for (let i = 0; i < arrayLength; i++) {
    const randomDigit = Math.floor(Math.random() * (max - min + 1)) + min;
    randomDigits.push(randomDigit);
  }

  return randomDigits;
}
const fillCardsWithDummyData = async () => {
  for (const key in tournamentData.value.players) {
    console.log(
      // key,
      //typeof tournamentData.value.players[key].phone
      getCardID(tournamentData.value.players[key].phone)
      // generateRandomStrokes()
    );
    const cardID = getCardID(tournamentData.value.players[key].phone);
    const cardRef = doc(db, "cards", cardID);
    const tempScore = generateRandomStrokes();
    await updateDoc(cardRef, {
      scores: tempScore,
    });
  }
};
const clearAllCards = async () => {
  for (const key in tournamentData.value.players) {
    console.log(
      // key,
      //typeof tournamentData.value.players[key].phone
      getCardID(tournamentData.value.players[key].phone)
      // generateRandomStrokes()
    );
    const cardID = getCardID(tournamentData.value.players[key].phone);
    const cardRef = doc(db, "cards", cardID);
    const tempScore = generateRandomStrokes();
    await updateDoc(cardRef, {
      scores: [],
    });
  }
};
const openAllCards = () => {
  for (const key in tournamentData.value.players) {
    console.log(
      // key,
      typeof tournamentData.value.players[key].phone
      // getCardID(tournamentData.value.players[key].phone),
      // generateRandomStrokes()
    );
    openCard(tournamentData.value.players[key].phone);
  }
};
</script>
<style scoped>
.noPadding {
  padding: 1px;
}
.birdie {
  background-color: #ffe6ea;
  border: 1px solid red;
}
.par {
  background-color: lightblue;
  border: 1px solid darkblue;
}
.bogey {
  background-color: lightgreen;
  border: 1px solid darkgreen;
}
.smallList {
  padding: 1px;
  font-size: 8px;
}
.hidden-row {
  display: none;
}
</style>

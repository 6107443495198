<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <button class="btn btn-sm btn-primary" @click="toHome()">Home</button>
      <button class="btn btn-sm btn-primary" @click="toScoring()">
        Scoring
      </button>
      <button class="btn btn-sm btn-primary" @click="toResults()">
        Results
      </button>
      <button class="btn btn-sm btn-success" @click="toTournament()">
        Tournament
      </button>
      <button class="btn btn-sm btn-primary" @click="toPlayers()">
        Players
      </button>
      <button class="btn btn-sm btn-primary" @click="toFourballs()">
        FourBalls
      </button>
      <button class="btn btn-sm btn-secondary" @click="logout">Logout</button>
    </div>
  </nav>
</template>
<script setup>
import { signOut } from "firebase/auth";
import { auth } from "../../firebase/config";
import { useRouter } from "vue-router";
const router = useRouter();
const logout = () => {
  signOut(auth)
    .then(() => {
      // Sign-out successful.
      console.log("player signout succesful");
      router.push("/");
    })
    .catch((error) => {
      // An error happened.
      console.log(error);
    });
};
const toTournament = () => {
  router.push("/tournament");
};
const toHome = () => {
  router.push("/adminhome");
};
const toPlayers = () => {
  router.push("/players");
};
const toResults = () => {
  router.push("/results");
};
const toScoring = () => {
  router.push("/scoring");
};
const toFourballs = () => {
  router.push("/fourballs");
};
</script>
